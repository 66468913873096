import React, {useState, useEffect, useRef} from "react";
import {
    Button,
    Checkbox,
    Message,
    Modal,
    Space,
    Table,
    Tag,
} from "@arco-design/web-react";
import {
    addCaseApi, addCaseAssistantApi,
    deleteCaseApi,
    getAssistantCasesApi, getAssistantsIdApi,
    getCasesApi, updateCaseApi,
    updateCaseAssistantBindingsApi,
} from "../../../api/normalApi";
import PropTypes from "prop-types";
import AddCaseCard from "./AddCaseCard";
import {
  addFileToVectorStore,
  deleteFileAPi,
  deleteVectorStoreFile,
  listVectorStoreFiles,
  // updateOpenaiAssistant
} from "../../../api/openaiAPI";

const CaseMaterialModal = ({
                               columns,
                               tableWidth,
                               selectedEmployee,
                               caseListVisible,
                               setCaseListVisible,
                           }) => {
    const [caseList, setCaseList] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const editMode = useRef("");
    const [currentCase, setCurrentCase] = useState({});
    const [selectedCases, setSelectedCases] = useState([]); // modal中选中的案例

    // 获取案例列表
    const getCases = async () => {
        try {
            // console.log(selectedEmployee)
            if(selectedEmployee){
                const res = await getAssistantCasesApi(selectedEmployee.id);
                if (res.status !== "success") {

                }
                const initSelectedList = res.data.map((item) => item.case_id);
                setSelectedCases(initSelectedList);

                const response = await getCasesApi();
                if (response.status !== "success") {

                }
                let data = response.data;

                const casesWithKey = data.map((item, index) => ({
                    ...item,
                    key: index + 1,
                }));
                setCaseList(casesWithKey);
            }
        } catch (error) {
            console.log(error)
            Message.error(`获取案例列表失败了，请稍后重试${error})`);
        }
    };

    const extractFileName = (filePath) => {
        if (!filePath) return ""; // 如果路径为空，返回空字符串

        // 使用正则表达式提取最后一个斜杠后面的部分
        return filePath.split("/").pop();
    };

    useEffect(() => {
        getCases().then(r => {
            console.log("getCases", r)
        });
        // eslint-disable-next-line
    }, []);

    // 新增案例行
    const handleAdd = () => {
        setModalVisible(true);
        editMode.current = "add";
        setCurrentCase("");
    };

    // 编辑案例
    const handleEdit = (record) => {
        setModalVisible(true);
        editMode.current = "edit";
        setCurrentCase(record);
    };

    // 删除案例
    const handleDelete = async () => {
        if (currentCase && currentCase.id) {
            const response = await deleteCaseApi(currentCase.id);
            await deleteCaseApi(deleteFileAPi(currentCase.openai_file_id));
            if (response.status === "success") {
                Message.success("删除案例成功");
                setModalVisible(false);
                await getCases();
            } else {
                Message.error("删除案例失败");
                setModalVisible(false);
            }
        }
    };

    // 保存案例
    const handleSave = async (updatedCase) => {
        console.log("save-case-page-modal",editMode)
        try {
            if (editMode.current === "add") {
                try {
                    const addResponse = await addCaseApi({...updatedCase});

                    if (addResponse.status === "success") {
                        if (selectedEmployee) {
                            const assistantResponse = await getAssistantsIdApi(selectedEmployee.id);
                            // let vectorStoreResponse = await listVectorStoreFiles(assistantResponse.data.openai_vs_id);
                            // let nowVsFileId = vectorStoreResponse.data;

                            const data = [{
                                case_id: addResponse.data.id,
                                assistant_id: selectedEmployee.id,
                            }];

                            try {
                                const bindResponse = await addCaseAssistantApi(data);
                                await addFileToVectorStore(assistantResponse.data.openai_vs_id, updatedCase.openai_file_id);

                                if (bindResponse.status !== "success") {
                                    return Message.error("绑定案例和助手失败");
                                }

                                await getCases();
                                Message.success("新增案例成功");
                            } catch (bindError) {
                                console.error("绑定案例和助手出错:", bindError);
                                Message.error("绑定过程中发生错误");
                            }
                        } else {
                            await getCases();
                            Message.success("新增案例成功");
                        }
                    } else {
                        Message.error("新增案例失败");
                    }
                } catch (addError) {
                    console.error("新增案例出错:", addError);
                    Message.error("新增案例过程中发生错误");
                }
            } else if (editMode.current === "edit") {
                try {
                    if (selectedEmployee) {
                        const assistantResponse = await getAssistantsIdApi(selectedEmployee.id);
                        let vectorStoreResponse = await listVectorStoreFiles(assistantResponse.data.openai_vs_id);
                        let nowVsFileId = vectorStoreResponse.data;
                        const isExist = nowVsFileId.some(item => item.id === updatedCase.openai_file_id);

                        if (!isExist) {
                            await addFileToVectorStore(assistantResponse.data.openai_vs_id, updatedCase.openai_file_id);
                        }
                    }

                    const updateResponse = await updateCaseApi(updatedCase.id, updatedCase);

                    if (updateResponse.status === "success") {
                        Message.success("修改案例成功");
                        await getCases();
                    } else {
                        Message.error("修改案例失败");
                    }
                } catch (updateError) {
                    console.error("更新案例出错:", updateError);
                    Message.error("更新案例过程中发生错误");
                }
            }

            setModalVisible(false);
        } catch (error) {
            console.error("处理保存操作出错:", error);
            Message.error("保存过程中发生错误");
        }
    };


    // 定义所有可能的列
    const allColumns = [
        {
            title: "编号",
            dataIndex: "key",
            width: 70,
        },
        {
            title: "资料名称",
            dataIndex: "case_name",
        },
        {
            title: "案例描述",
            dataIndex: "description",
        },
        {
            title: "案例文件",
            dataIndex: "file_url",
            render: (text) => (
                <a href={text} target="_blank" rel="noopener noreferrer">
                    {text ? extractFileName(text) : "暂无文件"}
                </a>
            ),
        },
        {
            title: (
                <div style={{display: "flex", justifyContent: "flex-end"}}>
                    <Button size={"small"} type="primary" onClick={handleAdd}>
                        新建
                    </Button>
                </div>
            ),
            dataIndex: "actions",
            align: "right", // 将这一列内容向右对齐
            render: (_, record) => (
                <div style={{textAlign: "right"}}>
                    <Button size={"small"} onClick={() => handleEdit(record)}>
                        编辑
                    </Button>
                </div>
            ),
        },
        {
            title: "资料类型",
            dataIndex: "is_internal",
            render: (text) => (
                <Tag color={text === true ? "orange" : "green"}>
                    {text === true ? "内部资料" : "公开资料"}
                </Tag>
            ),
        },
        {
            title: "选择",
            dataIndex: "check",
            render: (_, record) => {
                return (
                    <Checkbox
                        checked={selectedCases.includes(record.id)}
                        onChange={() => handleCheckboxChange(record.id)}
                    />
                );
            },
            width: 80,
        },
    ];

    const displayedColumns = columns
        ? allColumns.filter((col) => columns.includes(col.dataIndex))
        : allColumns;

    const handleCheckboxChange = (id) => {
        setSelectedCases((prevSelectedRows) => {
            if (prevSelectedRows.includes(id)) {
                return prevSelectedRows.filter((item) => item !== id); // 取消选择
            } else {
                return [...prevSelectedRows, id]; // 添加选择
            }
        });
    };

    const confirmCases = async () => {
        try {
            await updateCaseAssistantBindingsApi({
                assistant_id: selectedEmployee.id,
                case_ids: selectedCases
            });

            const response = await getAssistantsIdApi(selectedEmployee.id);
            let res = await listVectorStoreFiles(response.data.openai_vs_id)
            // 获取res.data中的id集合
            let now_vs_id = res.data.map(item => item.id);

            const selectedCaseList = caseList.filter((item) => selectedCases.includes(item.id));
            const openai_file_id = selectedCaseList
                .map((item) => item.openai_file_id)
                .filter(item => item !== "")
                .map(id => id.trim()); // 确保没有多余空格

            // 添加日志用于调试
            // console.log("当前VS中的文件:", now_vs_id);
            // console.log("选中案例的文件:", openai_file_id);

            // 找出需要添加的文件(确保格式一致的比较)
            let notIn = openai_file_id.filter(item =>
                !now_vs_id.map(id => id.trim()).includes(item.trim())
            );

            // 找出需要删除的文件(确保格式一致的比较)
            let notIn2 = now_vs_id.filter(item =>
                !openai_file_id.map(id => id.trim()).includes(item.trim())
            );

            // console.log("需要添加的文件:", notIn);
            // console.log("需要删除的文件:", notIn2);

            // 验证没有重复
            const intersection = notIn.filter(id => notIn2.includes(id));
            if (intersection.length > 0) {
                console.error("发现重复的文件ID:", intersection);
            }

            // 执行添加操作
            for (let i = 0; i < notIn.length; i++) {
                await addFileToVectorStore(selectedEmployee.assistant.openai_vs_id, notIn[i]);
            }

            // 执行删除操作
            for (let i = 0; i < notIn2.length; i++) {
                await deleteVectorStoreFile(selectedEmployee.assistant.openai_vs_id, notIn2[i]);
            }

            Message.success("修改案例绑定关系成功！");
            setCaseListVisible(false);
        } catch (error) {
            console.log("修改绑定关系请求错误", error);
            Message.error("修改绑定关系失败");
        }
    };

    return (
        <Modal
            title="勾选案例绑定到数字员工"
            visible={caseListVisible}
            onOk={confirmCases}
            onCancel={() => setCaseListVisible(false)}
            style={{width: "75vw"}}
        >
            <Space direction="vertical" style={{width: "100%"}}>
                <Table
                    size={"small"}
                    columns={displayedColumns}
                    data={caseList}
                    pagination={false}
                    rowKey="key"
                    style={{minWidth: tableWidth}}
                />
                <AddCaseCard
                    visible={modalVisible}
                    onClose={() => setModalVisible(false)}
                    caseData={currentCase}
                    setCaseData={setCurrentCase}
                    handleSave={handleSave}
                    handleDelete={handleDelete}
                />
            </Space>
        </Modal>
    );
};

CaseMaterialModal.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.string),
    tableWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    mode: PropTypes.oneOf(["displayModal", "normal"]),
    assistant_id: PropTypes.string,
};
export default CaseMaterialModal;
