// 基础配置
let normalServiceUrl = "https://go-endpoint.replit.app";

// 定义默认的模型服务URL
let modelServiceUrl;
// 海外服-云端用
// let modelServiceUrl_1 = 'https://zchat-model-service-1-eric166188.replit.app';

// 检查当前主机名
if (window.location.hostname === "localhost") {
  // 本地环境
  modelServiceUrl = "http://localhost:8000";
} else {
  // 云端环境
  modelServiceUrl = "https://zchat-model-service-1-eric166188.replit.app";
}

const headers = {
  "OpenAI-Beta": "assistants=v2",
  "Content-Type": "application/json",
};
const assistantId = "asst_5WFdXpWbssQZXOsyI2aeKK44";
const descriptionAssistantId = "asst_YrUqSVvvkRyUbCg4W1mqnlh1";

async function fetchApi(
  endpoint,
  method = "POST",
  data = {},
  stream = false,
  header = null,
) {
  try {
    const isFormData = data instanceof FormData;

    const response = await fetch(`${normalServiceUrl}${endpoint}`, {
      headers: isFormData ? header : header || headers,
      method: method,
      body: method === "GET" ? null : isFormData ? data : JSON.stringify(data), // 确保 data 始终正确传递
    });

    if (!response.ok) {
      console.log(response.statusText);
    }

    if (stream) {
      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8");
      let result = "";
      let finalResponse = {};

      while (true) {
        const { value, done } = await reader.read();
        if (done) break;
        result += decoder.decode(value, { stream: true });

        const events = result.split("\n\n");
        result = events.pop(); // 保留最后一个未完成的事件

        for (let event of events) {
          if (event.trim() === "") continue;
          const [eventTypeLine, dataLine] = event.split("\n");
          const eventType = eventTypeLine.replace("event: ", "").trim();
          let eventData;

          try {
            eventData = JSON.parse(dataLine.replace("data: ", "").trim());
          } catch (e) {
            eventData = dataLine.replace("data: ", "").trim();
          }

          if (eventType === "thread.message.completed") {
            finalResponse = eventData;
          }
        }
      }
      return finalResponse;
    } else {
      return await response.json();
    }
  } catch (error) {
    console.error(`Failed to fetch ${endpoint}:`, error);
    return null;
  }
}

// 创建会话（线程）
export async function createThread(
  messages = [],
  tool_resources = null,
  vector_store_ids = [],
  vector_stores = [],
  metadata = {},
) {
  const data = {
    messages: messages,
  };

  // 如果有工具资源（code_interpreter 或 file_search），则添加到数据
  if (tool_resources) {
    data.tool_resources = {};

    if (tool_resources.code_interpreter) {
      data.tool_resources.code_interpreter = tool_resources.code_interpreter;
    }

    if (tool_resources.file_search) {
      data.tool_resources.file_search = tool_resources.file_search;
    }
  }

  // 如果有向量存储 ID，添加到数据
  if (vector_store_ids.length > 0) {
    data.vector_store_ids = vector_store_ids;
  }

  // 如果有向量存储设置（包括文件 ID 和分块策略），添加到数据
  if (vector_stores.length > 0) {
    data.vector_stores = vector_stores.map((store) => ({
      file_ids: store.file_ids || [],
      chunking_strategy: store.chunking_strategy || { type: "auto" },
    }));
  }

  // 如果有元数据，添加到数据
  if (Object.keys(metadata).length > 0) {
    data.metadata = metadata;
  }

  return fetchApi("/threads", "POST", data);
}

// 获取对话消息列表
export async function getThreadMessages(thread_id, limit = 50) {
  return fetchApi(`/threads/${thread_id}/messages?limit=${limit}`, "GET");
}

// 发送聊天请求（发起对话）
export async function postRunByThreadBot(
  thread_id,
  instructions,
  assistant_id = assistantId,
) {
  let data = {
    model: "gpt-4o-mini",
    temperature: 0.1,
    assistant_id: assistant_id,
    stream: true,
    instructions,
  };
  return fetchApi(`/threads/${thread_id}/runs`, "POST", data, true);
}

// 发送描述的聊天请求
export async function generateDescriptionRuns(
  thread_id,
  assistant_id = descriptionAssistantId,
) {
  let data = {
    assistant_id: assistant_id,
    stream: true,
  };
  return fetchApi(`/threads/${thread_id}/runs`, "POST", data, true);
}

export async function generateDescriptionThreadAndRun(
  assistant_id = descriptionAssistantId,
  tool_resources,
) {
  let data = {
    assistant_id,
    tool_resources,
    stream: true,
  };
  return fetchApi(`/threads/runs`, "POST", data, true);
}

// list runs
export async function listRuns(thread_id) {
  return fetchApi(`/threads/${thread_id}/runs`, "GET");
}

// 发送聊天请求（发起对话）
export async function postRunByThreadBotCloud(
  thread_id,
  instructions,
  mode,
  employee_id = null,
  background = null,
  assistant_id = assistantId,
) {
  let data = {
    thread_id: thread_id,
    assistant_id: assistant_id,
    instructions: instructions,
    chat_mode: mode,
    employee_id: employee_id,
    background: background,
    test: true,
  };

  const response = await fetch(modelServiceUrl + `/api/chat/run_thread`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const errorData = await response.json();
    console.error("Error:", errorData);
    console.log(`Request failed with status ${response.status}`);
  }

  return response.json();
}
export async function postMessageByThreadBot(thread_id, role, content, file) {
  // 定义基础的消息数据
  let data = {
    role: role,
    content: [],
  };

  // 检查是否有文本内容，若有则添加
  if (content && content.trim()) {
    data.content.push({
      type: "text",
      text: content.trim(), // 确保去除多余的空白
    });
  }

  // 检查是否有文件，若有则添加
  if (file && file.id) {
    data.content.push({
      type: "image_file",
      image_file: {
        file_id: file.id,
        detail: "low",
      },
    });
  }

  // 确保只有当 content 或 file 存在时才发送请求
  if (data.content.length > 0) {
    return await fetchApi(`/threads/${thread_id}/messages`, "POST", data);
  } else {
    console.warn("No content or file to send.");
    return null; // 没有内容或文件时，不发送请求
  }
}

// 生成文件的描述词
export async function generateDescriptionApi(thread_id, fileId) {
  let data = {
    role: "user",
    content: [
      {
        type: "text",
        text: "描述",
      },
    ],
    attachments: [
      {
        file_id: fileId,
        tools: [
          {
            type: "file_search",
          },
        ],
      },
    ],
  };
  return await fetchApi(`/threads/${thread_id}/messages`, "POST", data);
}

export async function generatePic(img_url) {
  const prompt = `角色背景
 你是一个高效的图片分析员，能够准确地读取图片内容，并生成简洁明了的图片标题和详细的图片描述（你生成的文件和描述后面会被工作人员进行检索）
 任务1:生成图片标题
 1. 仔细阅读文件内容，提取关键信息。
 2. 根据关键信息，生成一个简洁且能概括文件主要内容的标题。
 3. 标题长度控制在 20 个字以内。
 
 任务2:生成图片描述
 1. 根据分析图片内容，梳理出主要内容和重要细节。
 2. 用清晰、流畅的语言将这些内容组织成图片描述。
 3. 图片描述长度控制在 100 字以内。
 ## 限制
 - 只处理与文件内容相关的任务，拒绝回答与文件无关的问题。
 - 所输出的内容必须按照给定的格式进行组织，不能偏离框架要求。
 - 标题和描述要准确反映文件内容并且标题和描述要有关联性。
 
 # 输出格式
 无论什么文件，都请按照以下 JSON 格式输出结果：
 {
   "标题":"图片标题",
   "描述":"图片的描述"
 }`;

  const data = {
    model: "gpt-4o-mini",
    messages: [
      {
        role: "user",
        content: [
          {
            type: "text",
            text: prompt,
          },
          {
            type: "image_url",
            image_url: { url: img_url },
          },
        ],
      },
    ],
    response_format: { type: "json_object" },
  };
  return fetchApi("/chat/completions", "POST", data);
}

//删除某一条消息
export async function deleteMessageApi(thread_id, message_id) {
  return fetchApi(`/threads/${thread_id}/messages/${message_id}`, "DELETE");
}

export async function updateMessageApi(thread_id, message_id, meta_data) {
  return fetchApi(`/threads/${thread_id}/messages/${message_id}`, "POST", {
    metadata: meta_data,
  });
}

export async function uploadPictureFileAPi(file) {
  //只允许上传图片等资料
  const formData = new FormData();
  formData.append("file", file);
  formData.append("purpose", "vision");
  return fetchApi(`/files`, "POST", formData, false, {
    "OpenAI-Beta": "assistants=v2",
  });
}

export async function uploadFileAPi(file) {
  //允许上传文件
  const formData = new FormData();
  formData.append("file", file);
  formData.append("purpose", "assistants");
  return fetchApi(`/files`, "POST", formData, false, {
    "OpenAI-Beta": "assistants=v2",
  });
}

export async function createOpenaiVStore(name) {
  const data = { name: name };
  return fetchApi(`/vector_stores`, "POST", data, false, {
    "OpenAI-Beta": "assistants=v2",
    "Content-Type": "application/json",
  });
}

export async function addFileToVectorStore(vectorStoreId, fileId) {
  const url = `/vector_stores/${vectorStoreId}/files`;
  const headers = {
    "Content-Type": "application/json",
    "OpenAI-Beta": "assistants=v2",
  };
  const data = {
    file_id: fileId,
  };

  try {
    return await fetchApi(url, "POST", data, false, headers);
  } catch (error) {
    console.error("Failed to add file to vector store:", error);
    return null;
  }
}

// 新增：从向量存储中删除文件
export async function deleteVectorStoreFile(vectorStoreId, fileId) {
  const url = `/vector_stores/${vectorStoreId}/files/${fileId}`;
  const headers = {
    "Content-Type": "application/json",
    "OpenAI-Beta": "assistants=v2",
  };

  try {
    return await fetchApi(url, "DELETE", null, false, headers);
  } catch (error) {
    console.error("Failed to delete file from vector store:", error);
    return null;
  }
}

export async function getOpenaiVStoreFile(vector_store_id, file_id) {
  return fetchApi(`/vector_stores/${vector_store_id}/files/${file_id}`, "GET");
}

// 新增：获取向量存储的文件列表
export async function listVectorStoreFiles(vectorStoreId) {
  return fetchApi(`/vector_stores/${vectorStoreId}/files`, "GET", null, false, {
    "OpenAI-Beta": "assistants=v2",
    "Content-Type": "application/json",
  });
}

export async function deleteFileAPi(file_id) {
  return fetchApi(`/files/${file_id}`, "DELETE");
}

//请求图片内容的接口
export async function getPictureContentApi(id) {
  const response = await fetch(
    `https://go-endpoint.replit.app/files/${id}/content`,
  );
  if (!response.ok) {
    console.log(`HTTP error! status: ${response.status}`);
  } else {
    return response.blob();
  }
}

//获取文件内容
export async function getFileContentApi(id) {
  return fetchApi(`/files/${id}`, "GET");
}

//修改已读消息
export async function modifyMessageReadApi(thread_id, message_id, data) {
  return fetchApi(`/threads/${thread_id}/messages/${message_id}`, "POST", data);
}

//ai生成消息
export async function chatAgainApi(thread_id, assistant_id) {
  const data = {
    thread_id: thread_id,
    assistant_id: assistant_id,
    manual: true,
  };

  const response = await fetch(modelServiceUrl + `/api/chat/chat_again`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const errorData = await response.json();
    console.error("Error:", errorData);
    console.log(`Request failed with status ${response.status}`);
  }

  return response.json();
}

// 创建新的助手
export async function createOpenaiAssistant(config) {
  const data = {
    instructions: config.instructions,
    name: config.name,
    // tools: config.tools || [],
    model: config.model || "gpt-4o",
    // file_ids: config.file_ids || [],
    // metadata: config.metadata || {}
  };

  return fetchApi("/assistants", "POST", data);
}

// 更新现有助手
export async function updateOpenaiAssistant(assistant_id, config) {
  const data = {
    instructions: config.instructions,
    name: config.name,
    tools: config.tools,
    model: config.model,
    file_ids: config.file_ids,
    metadata: config.metadata
  };

  // 移除未定义的属性
  Object.keys(data).forEach(key => {
    if (data[key] === undefined) {
      delete data[key];
    }
  });

  return fetchApi(`/assistants/${assistant_id}`, "POST", data);
}
