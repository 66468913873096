import React from 'react';
import PropTypes from 'prop-types';
import { Tabs } from '@arco-design/web-react';
import ProductList from "./components/ProductList";
import CaseMaterial from './components/CaseMaterialTable';

const TabPane = Tabs.TabPane;

const SalesMaterial = ({
                           productColumns,
                           caseColumns,
                           productTableStyle,
                           caseTableStyle
                       }) => {
    return (
        <Tabs defaultActiveTab="products">
            <TabPane key="products" title="商品列表">
                <ProductList
                    columns={productColumns}
                    tableStyle={productTableStyle}
                />
            </TabPane>
            <TabPane key="cases" title="案例列表">
                <CaseMaterial
                    columns={caseColumns}
                    tableStyle={caseTableStyle}
                />
            </TabPane>
        </Tabs>
    );
};

SalesMaterial.propTypes = {
    productColumns: PropTypes.arrayOf(PropTypes.string),
    caseColumns: PropTypes.arrayOf(PropTypes.string),
    productTableStyle: PropTypes.object,
    caseTableStyle: PropTypes.object
};

export default SalesMaterial;