// src/pages/AgentList/ListPage/component/EmployeeTable.js

import React, {useState, useEffect, useCallback, useRef} from "react";
import {
    Table,
    Switch,
    Space,
    Button,
    Message,
    Modal,
    Popconfirm,
    Dropdown,
    Menu,
} from "@arco-design/web-react";
import {IconWechat, IconDown, IconUp, IconPlus} from "@arco-design/web-react/icon";
import {
    addAssistantApi, // 添加员工的接口
    getAssistantsWithWeChatApi,
    logoutWechatApi,
    updateWechatAutoReplyApi,
    deleteAssistantApi,
    loginWechatApi,
    getAssistantProductsApi,
    addProductAssistantApi,
    getAssistantCasesApi,
    addCaseAssistantApi,
    getWeChats,
    cancelWechatApi,
} from "../../../../api/normalApi";
import {useNavigate} from "react-router-dom";
import {QRCodeSVG} from "qrcode.react";

// 在组件顶部添加配置映射
const GROUP_CONFIG_MAP = {
    1: "全部都回复",
    2: "被@才回复",
    3: "被@后总回"
};

const NEW_CHAT_CONFIG_MAP = {
    1: "全部都回复",
    2: "仅私聊回复",
    3: "仅群聊回复"
};

// 员工列表组件
const EmployeeTable = ({setSelectedEmployee}) => {
    const [employeeList, setEmployeeList] = useState([]);
    const maxSeats = 20; // 最大员工数
    const navigate = useNavigate(); // 初始化 useNavigate
    const [qrCode, setQrCode] = useState(null);
    const [intervalActive, setIntervalActive] = useState(false);
    const [previousUpdatedAt, setPreviousUpdatedAt] = useState({}); // 存储每个账号的 updated_at
    // const [isLoggingIn, setIsLoggingIn] = useState(false); // 标识是否有登录正在进行
    const wxInstance = useRef();
    const [groupDropdownOpen, setGroupDropdownOpen] = useState({}); // 群聊回复的下拉状态
    const [newChatDropdownOpen, setNewChatDropdownOpen] = useState({}); // 新聊天的下拉状态
    const [groupSwitchChecked, setGroupSwitchChecked] = useState({}); // 群聊回复开关状态
    const [newChatSwitchChecked, setNewChatSwitchChecked] = useState({}); // 新聊天开关状态
    const [globalSwitchChecked, setGlobalSwitchChecked] = useState({}); // 全局回复开关状态
    const [privateSwitchChecked, setPrivateSwitchChecked] = useState({}); // 私聊回复开关状态

    const handleDeleteAssistant = async (assistantId) => {
        Modal.confirm({
            title: "确认删除该员工吗？",
            content: (
                <div style={{textAlign: "center"}}>
                    <p>删除后，该员工的所有信息将无法恢复</p>
                </div>
            ),
            onOk: async () => {
                try {
                    const data = await deleteAssistantApi(assistantId);
                    if (data.status === 200 || data.status === "success") {
                        await getAssistantsWxIds().then(
                            Message.success("删除员工成功")
                        )
                    } else {
                        Message.error("删除员工失败，请稍后重试");
                    }
                } catch (error) {
                    Message.error("删除员工失败，请稍后重试");
                }
            },
        });
    };

    // 处理员工和微信账号数据
    const processEmployeeData = (data, unboundedData) => {
        if (data === null) return [];
        let result = [];
        data.forEach((employee) => {
            if (employee.wechats.length === 0) {
                // 查找 unboundedData 中与当前 employee 匹配的记录
                const unbounded = unboundedData.find(
                    (unbound) => unbound.assistant_id === employee.assistant.id,
                );

                result.push({
                    ...employee,
                    id: employee.assistant.id, // 确保有 id 字段
                    name: employee.assistant.name,
                    wx_id: null,
                    nickname: "未绑定",
                    auto_reply: unbounded ? unbounded.auto_reply : false,
                    is_online: unbounded ? unbounded.is_online : false,
                });
            } else {
                employee.wechats.forEach((wechat, index) => {
                    result.push({
                        ...wechat,
                        ...employee,
                        id: employee.assistant.id, // 确保有 id 字段
                        name: index === 0 ? employee.assistant.name : "",
                        wx_id: wechat.wx_id,
                        nickname: wechat.nickname,
                        auto_reply: wechat.auto_reply,
                        is_online: wechat.is_online,
                    });
                });
            }
        });

        if(unboundedData){
            // 处理没有绑定助手的微信账号
            unboundedData.forEach((unbound) => {
                // 检查 unbound 是否在已有绑定的 employee 数据中
                const isBound = data.some(
                    (employee) => employee.assistant.id === unbound.assistant_id,
                );
                if (!isBound) {
                    result.push({
                        assistant: "",
                        wechats: [
                            {
                                wx_id: unbound.wx_id,
                                nickname: unbound.nickname,
                                auto_reply: unbound.auto_reply,
                                is_online: unbound.is_online,
                            },
                        ],
                        id: `unattached-${unbound.wx_id}`,
                    });
                }
            });
        }

        return result;
    };

    // 获取员工及微信账号数据
    const getAssistantsWxIds = useCallback(async () => {
        // 判断是否有现有账号的 updated_at 变化
        const updatedAtChanged = employeeList.some((account) => {
            const previous = previousUpdatedAt[account.wx_id];
            return previous && previous !== account.updated_at;
        });

        if (updatedAtChanged) {
            // 登录成功，已有账号的 updated_at 发生变化
            setIntervalActive(false);
            setQrCode(null);
            // setIsLoggingIn(false);
            setPreviousUpdatedAt(
                employeeList.reduce((acc, account) => {
                    acc[account.wx_id] = account.updated_at;
                    return acc;
                }, {}),
            );
        }

        try {
            const {data: assistantData} = await getAssistantsWithWeChatApi(); // 调用新接口获取数据
            const {data: unboundedData} = await getWeChats();
            const processedData = processEmployeeData(assistantData, unboundedData); // 处理数据

            // 初始化所有回复相关的状态
            const newGlobalSwitchChecked = {};
            const newPrivateSwitchChecked = {};
            const newGroupSwitchChecked = {};
            const newNewChatSwitchChecked = {};

            if(assistantData){
                // 处理已绑定助手的微信账号
                assistantData.forEach(assistant => {
                    assistant.wechats.forEach(wechat => {
                        if (wechat.wx_id) {
                            newGlobalSwitchChecked[wechat.wx_id] = wechat.auto_reply || false;
                            newPrivateSwitchChecked[wechat.wx_id] = wechat.private_auto_reply || false;
                            newGroupSwitchChecked[wechat.wx_id] = wechat.group_auto_reply || false;
                            newNewChatSwitchChecked[wechat.wx_id] = wechat.new_auto_reply || false;
                        }
                    });
                });

                // 处理未绑定助手的微信账号
                unboundedData.forEach(account => {
                    if (account.wx_id) {
                        newGlobalSwitchChecked[account.wx_id] = account.auto_reply || false;
                        newPrivateSwitchChecked[account.wx_id] = account.private_auto_reply || false;
                        newGroupSwitchChecked[account.wx_id] = account.group_auto_reply || false;
                        newNewChatSwitchChecked[account.wx_id] = account.new_auto_reply || false;
                    }
                });

                // 更新所有开关状态
                setGlobalSwitchChecked(newGlobalSwitchChecked);
                setPrivateSwitchChecked(newPrivateSwitchChecked);
                setGroupSwitchChecked(newGroupSwitchChecked);
                setNewChatSwitchChecked(newNewChatSwitchChecked);
            }
            setEmployeeList(processedData);
        } catch (error) {
            Message.error("获取员工及微信账号信息失败");
            console.log(error);
        }
    }, [employeeList, previousUpdatedAt]);

    useEffect(() => {
        getAssistantsWxIds(); // 获取数据
        // eslint-disable-next-line
    }, []);

    // 设置轮询
    useEffect(() => {
        let intervalId;
        if (intervalActive) {
            intervalId = setInterval(async () => {
                await getAssistantsWxIds();
            }, 5000); // 将轮询间隔设置为5秒
        }

        return () => clearInterval(intervalId);
    }, [intervalActive, getAssistantsWxIds]);

    // 更新自动回复开关
    const handleReplyConfigChange = async (wxID, autoMode, switchChecked, newAutoReplyStatus, config = null) => {
        let back_data = {}
        if (autoMode === 'global') {
            back_data = {
                wx_user_id: wxID,
                auto_reply: switchChecked,
            };
            // 更新全局回复开关状态
            setGlobalSwitchChecked(prev => ({
                ...prev,
                [wxID]: switchChecked
            }));
        } else if (autoMode === 'private') {
            back_data = {
                wx_user_id: wxID,
                private_auto_reply: switchChecked,
            };
            // 更新私聊回复开关状态
            setPrivateSwitchChecked(prev => ({
                ...prev,
                [wxID]: switchChecked
            }));
        } else if (autoMode === 'group') {
            back_data = {
                wx_user_id: wxID,
                group_auto_reply: switchChecked,
            };
            if (config) {
                back_data.group_config = config;
            }
            // 更新群聊开关状态
            setGroupSwitchChecked(prev => ({
                ...prev,
                [wxID]: switchChecked
            }));
        } else if (autoMode === 'new_chat') {
            back_data = {
                wx_user_id: wxID,
                new_auto_reply: switchChecked,
            };
            if (config) {
                back_data.new_config = config;

            }
            // 更新新聊天开关状态
            setNewChatSwitchChecked(prev => ({
                ...prev,
                [wxID]: switchChecked
            }));
        }

        const res = await updateWechatAutoReplyApi(back_data);
        if (res.status === 200) {
            getAssistantsWxIds(); // 获取数据
            Message.success("自动回复状态更新成功");
        } else {
            Message.error("自动回复状态更新失败");
        }
        // 更新状态
        const updatedList = employeeList.map((item) =>
            item.wx_id === wxID
                ? {...item, auto_reply: newAutoReplyStatus}
                : item,
        );
        setEmployeeList(updatedList);

    };

    // 选择员工，点击“编辑”按钮时调用
    const handleSelectedEmployee = (row) => {
        setSelectedEmployee(row); // 传递选中的员工数据
        navigate(`/employees/${row.id}`); // 跳转到编辑路由，并传递员工ID
    };

    // 确认退出微信
    const confirmLogout = (accountId) => {
        Modal.confirm({
            title: "确认退出登录吗？",
            content: (
                <div style={{textAlign: "center"}}>
                    <p>退出登录微信后将不再接收和回复消息，且绑定的助手也将解绑</p>
                </div>
            ),
            onOk: async () => {
                logoutWx(accountId);
            },
        });
    };

    //登出微信
    const logoutWx = useCallback(async (accountId) => {
        logoutWechatApi({wx_user_id: accountId}).then(async (data) => {
            if (data.status === 200) {
                await getAssistantsWxIds();
                Message.success("退出登录成功");
            }
        });
        // eslint-disable-next-line
    }, []);

    // 添加员工
    const handleAddEmployee = async () => {
        const remainingSeats = maxSeats - employeeList.length; // 剩余席位
        if (remainingSeats > 0) {
            const newEmployee = {
                name: `新员工`,
                // auto_reply: false,
                instructions: "你是一个智能助手，在微信上陪好客户",
                draft_instructions: "你是一个智能助手，在微信上陪好客户",
                sales_process: "{}",
            };

            try {
                const data = await addAssistantApi(newEmployee); // 调用添加员工 API
                if (data.status === "success") {
                    await getAssistantsWxIds(); // 刷新员工列表
                    Message.success(`已添加 ${newEmployee.name}`);
                } else {
                    Message.error("添加员工失败，请稍后重试");
                }
            } catch (error) {
                Message.error("添加员工失败，请稍后重试");
            }
        } else {
            Message.warning("已无剩余席位");
        }
    };

    // 登录函数
    const handleLogin = async () => {
        // setIsLoggingIn(true); // 设置登录状态为进行中

        try {
            const data = await loginWechatApi();
            if (data && data.data && data.data.qr_code) {
                setQrCode(data.data.qr_code);
                wxInstance.current = data.data.wx_instance_name;
                setIntervalActive(true);
            } else {
                console.log("没有获取到登录数据");
                Message.error("无法获取二维码，请重试");
            }
        } catch (err) {
            console.log(err);
            Message.error("登录失败，请重试");
        }
    };

    const cancelLogin = () => {
        cancelWechatApi({wx_instance_name: wxInstance.current}).then((res) => {
            if (res.status !== 200) {
                console.log(res);
            }
        });
        setIntervalActive(false);
    };

    // 复制助手
    const handleCopy = async (row) => {
        const back_data = {
            name: row.assistant.name + "-副本",
            instructions: row.assistant.instructions,
            sales_process: row.assistant.sales_process,
            draft_instructions: row.assistant.draft_instructions,
            role_instructions: row.assistant.role_instructions,
        };
        let assistantId;
        const res = await addAssistantApi(back_data);
        if (res.status === "success") {
            assistantId = res.data.id;
        } else {
            Message.error("复制助手失败");
            console.log(res);
            return; // 失败时提前返回
        }
        Promise.all([
            getAssistantProductsApi(row.id).then((res) => {
                const productArray = res.data.map((item) => ({
                    product_id: item.product_id,
                    assistant_id: assistantId,
                }));
                return addProductAssistantApi(productArray);
            }),
            getAssistantCasesApi(row.id).then((res) => {
                const caseArray = res.data.map((item) => ({
                    case_id: item.case_id,
                    assistant_id: assistantId,
                }));
                return addCaseAssistantApi(caseArray);
            }),
        ])
            .then(async (results) => {
                await getAssistantsWxIds();
                Message.success("复制助手成功");
            })
            .catch((error) => {
                console.error("发生错误:", error);
                Message.error("执行失败");
            });
    };


    // 动态生成表格列
    const columns = [
        {
            title: "数字员工",
            width: 300,
            dataIndex: "name",
            key: "name",
            render: (name) => <span>{name || " "}</span>,
        },
        {
            title: "发布账号",
            dataIndex: "nickname",
            key: "nickname",
            width: 350,
            render: (nickname, row) => (
                <Space style={{width: '90%', justifyContent: 'space-between'}}>
                    <Space>
                        <IconWechat style={{
                            color:
                                row.is_online || row?.wechats[0]?.is_online
                                    ? "#00B42A"
                                    : "#C9CDD4"}}/>
                        <span
                            style={{
                                color:
                                    row.is_online || row?.wechats[0]?.is_online
                                        ? "black"
                                        : "#C9CDD4",
                            }}
                        >
                        {row.assistant === "" ? row.wechats[0].nickname : nickname}
                        </span>
                    </Space>
                    {
                        row.is_online ? (
                            <Button
                                type="text"
                                status="danger"
                                disabled={row.assistant === ""}
                                onClick={() => {
                                    confirmLogout(row.wx_id);
                                }}
                            >
                                退出账号
                            </Button>
                        ) : row.assistant === "" ? (
                            <Popconfirm
                                focusLock
                                title=""
                                icon={null}
                                content={
                                    <div
                                        style={{
                                            textAlign: "center",
                                            width: "200px",
                                            height: "220px",
                                        }}
                                    >
                                        <h3>使用实名认证过（绑过卡）的微信扫码登录帐号接入</h3>
                                        {qrCode && (
                                            <>
                                                <QRCodeSVG
                                                    value={qrCode}
                                                    size={150}
                                                />
                                            </>
                                        )}
                                    </div>
                                }
                                footer={null}
                                onOk={cancelLogin}
                                onCancel={cancelLogin}
                            >
                                <Button
                                    type="outline"
                                    onClick={handleLogin}
                                >
                                    重新登录
                                </Button>
                            </Popconfirm>
                        ) : row.wx_id ? <Popconfirm
                            focusLock
                            title=""
                            icon={null}
                            content={
                                <div
                                    style={{
                                        textAlign: "center",
                                        width: "200px",
                                        height: "220px",
                                    }}
                                >
                                    <h3>使用实名认证过（绑过卡）的微信扫码登录帐号接入</h3>
                                    {qrCode && (
                                        <>
                                            <QRCodeSVG
                                                value={qrCode}
                                                size={150}
                                            />
                                        </>
                                    )}
                                </div>
                            }
                            footer={null}
                            onOk={cancelLogin}
                            onCancel={cancelLogin}
                        >
                            <Button
                                type="outline"
                                onClick={handleLogin}
                            >
                                重新登录
                            </Button>
                        </Popconfirm> : ''
                    }
                </Space>
            ),
        },
        {
            title: "编辑",
            dataIndex: "edit",
            width: 80,
            key: "edit",
            render: (_, row) => (
                <Button
                    style={{marginLeft: "-15px"}}
                    type="text"
                    disabled={row.assistant === ""}
                    onClick={() => handleSelectedEmployee(row)}
                >
                    编辑
                </Button>
            ),
        },
        {
            title: "总开关",
            dataIndex: "auto_reply",
            key: "auto_reply",
            width: 100,
            render: (autoReply, record) => (
                <Space>
                    {!record.wx_id || !record.is_online ? "" : <Switch
                        checked={globalSwitchChecked[record.wx_id] ?? autoReply}
                        disabled={!record.wx_id || !record.is_online}
                        onChange={(checked) => handleReplyConfigChange(record.wx_id, 'global', checked, autoReply)}
                    />}
                </Space>

            ),
        },
        {
            title: "私聊回复",
            dataIndex: "private_auto_reply",
            key: "private_auto_reply",
            width: 120,
            render: (privateReply, record) => (
                <Space>
                    {!record.wx_id || !record.is_online ? "" :
                        <Switch
                            checked={privateSwitchChecked[record.wx_id] ?? privateReply}
                            disabled={!record.wx_id || !record.is_online}
                            onChange={(checked) => handleReplyConfigChange(record.wx_id, 'private', checked, privateReply)}
                        />}
                </Space>

            ),
        },
        {
            title: "群聊回复",
            dataIndex: "group_auto_reply",
            key: "group_auto_reply",
            width: 200,
            render: (groupReply, record) => (
                <Space style={{
                    width: '100%'
                    // , justifyContent: 'space-between'
                }}>
                    {!record.wx_id || !record.is_online ? "" :
                        <Space>
                            <Switch
                                checked={groupSwitchChecked[record.wx_id] ?? groupReply}
                                disabled={!record.wx_id || !record.is_online}
                                onChange={(checked) => handleReplyConfigChange(record.wx_id, 'group', checked, groupReply)}
                            />
                            <Dropdown
                                trigger='click'
                                position='br'
                                onVisibleChange={(visible) => {
                                    setGroupDropdownOpen(prev => ({
                                        ...prev,
                                        [record.wx_id]: visible
                                    }));
                                }}
                                droplist={
                                    <Menu
                                        onClickMenuItem={(key) => handleReplyConfigChange(record.wx_id, 'group', groupSwitchChecked[record.wx_id] ?? groupReply, null, key)}>
                                        <Menu.Item key="1">全部都回复</Menu.Item>
                                        <Menu.Item key="2">被@才回复</Menu.Item>
                                        <Menu.Item key="3">被@后总回</Menu.Item>
                                    </Menu>
                                }
                            >
                                <Space size={0}>
                                    <Button
                                        type='outline'
                                        style={{borderRadius: '2px 0 0 2px'}}
                                    >
                                        <span>{GROUP_CONFIG_MAP[record.group_config] || "全部都回复"}</span>
                                    </Button>
                                    <Button
                                        type='primary'
                                        style={{
                                            width: '30px',
                                            borderRadius: '0 2px 2px 0',
                                            marginLeft: '-1px'
                                        }}
                                        icon={groupDropdownOpen[record.wx_id] ? <IconUp/> : <IconDown/>}
                                    />
                                </Space>
                            </Dropdown>
                        </Space>
                    }
                </Space>
            ),
        },
        {
            title: "新聊天自动回复",
            dataIndex: "new_auto_reply",
            key: "new_auto_reply",
            width: 250,
            render: (newChatAutoReply, record) => (
                <Space style={{width: '100%'}}>
                    {!record.wx_id || !record.is_online ? "" :
                        <Space>
                            <Switch
                                checked={newChatSwitchChecked[record.wx_id] ?? newChatAutoReply}
                                disabled={!record.wx_id || !record.is_online}
                                onChange={(checked) => handleReplyConfigChange(record.wx_id, 'new_chat', checked, newChatAutoReply)}
                            />
                            <Dropdown
                                trigger='click'
                                position='br'
                                onVisibleChange={(visible) => {
                                    setNewChatDropdownOpen(prev => ({
                                        ...prev,
                                        [record.wx_id]: visible
                                    }));
                                }}
                                droplist={
                                    <Menu
                                        onClickMenuItem={(key) => handleReplyConfigChange(record.wx_id, 'new_chat', newChatSwitchChecked[record.wx_id] ?? newChatAutoReply, null, key)}>
                                        <Menu.Item key="1">全部都回复</Menu.Item>
                                        <Menu.Item key="2">仅私聊回复</Menu.Item>
                                        <Menu.Item key="3">仅群聊回复</Menu.Item>
                                    </Menu>
                                }
                            >
                                <Space size={0}>
                                    <Button
                                        type='outline'
                                        style={{borderRadius: '2px 0 0 2px'}}
                                    >
                                        <span>{NEW_CHAT_CONFIG_MAP[record.new_config] || "全部都回复"}</span>
                                    </Button>
                                    <Button
                                        type='primary'
                                        style={{
                                            width: '30px',
                                            borderRadius: '0 2px 2px 0',
                                            marginLeft: '-1px'
                                        }}
                                        icon={newChatDropdownOpen[record.wx_id] ? <IconUp/> : <IconDown/>}
                                    />
                                </Space>
                            </Dropdown>
                        </Space>
                    }

                </Space>
            ),
        },
        {
            title: "复制",
            dataIndex: "copy",
            key: "copy",
            width: 80,
            render: (_, row) => (
                row.name ? <Button
                    type="text"
                    style={{marginLeft: "-15px"}}
                    disabled={row.assistant === ""}
                    onClick={() => handleCopy(row)}
                >
                    复制
                </Button> : ''
            ),
        },
        {
            title: "操作",
            dataIndex: "operation",
            key: "operation",
            width: 80,
            render: (_, row) =>
                !row.wx_id && !row.is_online ?
                    (
                        <Button
                            type="text"
                            status="danger"
                            style={{marginLeft: "-15px"}}
                            disabled={row.assistant === ""}
                            onClick={() => handleDeleteAssistant(row.id)} // 调用删除函数
                        >
                            删除
                        </Button>
                    ) : (
                        ""
                    ),
        },
    ];

    return (
        <div style={{marginRight: 10}}>
            <Space align={"center"}>
                <h3 style={{marginTop: 16}}>员工列表 {employeeList.length}/{maxSeats} </h3>
                <Button type='primary' icon={<IconPlus/>} onClick={handleAddEmployee} size={"mini"}
                        disabled={employeeList.length >= maxSeats}/>
            </Space>
            <Table
                columns={columns}
                data={employeeList}
                rowKey={(record) => record.wx_id || record.id} // 如果没有微信账号，使用员工 ID 作为 key
                pagination={false}
            />
        </div>
    );
};

export default EmployeeTable;
