import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback,
} from "react";
import {
  Avatar,
  Button,
  Card,
  Empty,
  Image,
  Input,
  Message,
  Space,
  Spin,
  Tag,
  Tooltip,
  Typography,
  Upload,
} from "@arco-design/web-react";
import {
  IconRefresh,
  IconCopy,
  IconDelete,
  IconFileImage,
  IconSend,
  IconWechat,
  IconFile,
} from "@arco-design/web-react/icon";
import {
  createThread,
  deleteFileAPi,
  deleteMessageApi,
  getPictureContentApi,
  getThreadMessages,
  postMessageByThreadBot,
  postRunByThreadBotCloud,
  updateMessageApi,
  uploadPictureFileAPi,
} from "../../../api/openaiAPI";
import LaunchModal from "./components/ChatTest/LaunchModal";
import { getCOSDownLoadTokenApi } from "../../../api/normalApi";
import COS from "cos-js-sdk-v5";
import { formatTimestampToDateTime } from "../../../utils/format";
import localforage from "localforage";

const { Text } = Typography;

const ChatTest = ({
  threadId,
  setThreadId,
  draftInstructions,
  selectedEmployee,
  chatMode,
  chatBackground,
  handleBackgroundChange,
}) => {
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [replyMode, setReplyMode] = useState("quick");
  const [imageUrls, setImageUrls] = useState({}); // 存储图片的URL
  const [file, setFile] = React.useState();
  const [isUserScrolling, setIsUserScrolling] = useState(false); //是否允许气泡content滑动
  const contentRef = useRef(null);
  const salesMode = useRef(0);
  const disableValue = useRef(false); //是否允许输入框输入
  const [isLoadingChat, setIsLoadingChat] = useState(false); //是否在加载对话
  const cosData = useRef();
  const userInfo = JSON.parse(localStorage.getItem("user_info"));
  const timeoutRef = useRef(null); // 添加useRef来存储定时器ID
  const [messageType, setMessageType] = useState({});

  const [sysImageUrls, setSysImageUrls] = useState({}); // 存储图片的URL
  const PicRenderer = React.memo(({ sentence }) => {
    const [url, setUrl] = useState(sysImageUrls[sentence] || null);
    const fetchImageUrl = useCallback(async () => {
      if (!url) {
        const regex = /(?:https:\/\/.+\/)([^/]+)\/(.+)/;
        const match = sentence.match(regex);
        // Try to get from localforage using match[0] as the key
        const storedBase64 = await localforage.getItem(match[0]);
        if (storedBase64) {
          setSysImageUrls((prev) => ({ ...prev, [sentence]: storedBase64 }));
          setUrl(storedBase64);
        } else {
          const result = await getCOSDownLoadPicToken(sentence);
          setSysImageUrls((prev) => ({ ...prev, [sentence]: result }));
          setUrl(result);
        }
      }
      // eslint-disable-next-line
    }, [sentence]); // 只依赖于 sentence

    useEffect(() => {
      fetchImageUrl().then((r) => {});
      // eslint-disable-next-line
    }, [fetchImageUrl]);

    return url ? (
      <Image
        src={url}
        alt="系统图片"
        width={250}
        style={{
          height: "auto",
        }}
      />
    ) : (
      <span>加载中...</span>
    );
  });

  const msgMap = {
    pic: (sentence) => <PicRenderer sentence={sentence} />,
    normal: (sentence) => <span>自定义的</span>,
    // 可以添加更多类型
    file: (sentence) => msgDisplayCard(sentence),
  };

  useEffect(() => {
    messages
      .slice()
      .reverse()
      .forEach((msg) => {
        msg.content.forEach((item, contentIndx) => {
          if (item && item.text && item.text.value) {
            item.text.value
              .split(/(?<![?？])([?？!。\n])/g)
              .reduce((acc, curr, i, arr) => {
                // 如果当前片段后面是?，且再后面以'开头，则组合它们
                if (
                  i % 2 === 0 &&
                  arr[i + 1] === "?" &&
                  arr[i + 2]?.startsWith("'")
                ) {
                  acc.push(curr + "?" + arr[i + 2]);
                  return acc;
                }
                // 跳过已经被组合的片段
                if (i % 2 === 0 && arr[i - 1] === "?" && curr.startsWith("'")) {
                  return acc;
                }
                // 正常添加其他片段
                if (i % 2 === 0) {
                  acc.push(curr + (arr[i + 1] || ""));
                }
                return acc;
              }, [])
              .filter((sentence) => sentence.trim().length > 0) // 过滤掉空句子
              .forEach((sentence, sentenceIndex) => {
                setMessageType((prev) => ({
                  ...prev,
                  [sentence]: isCard(sentence),
                }));
              });
          }
        });
      });
  }, [messages]);

  const scrollToBottom = () => {
    if (contentRef.current) {
      contentRef.current.scrollTo({
        top: contentRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    if (chatMode === "service") {
      salesMode.current = 0;
    } else if (chatMode === "sales") {
      salesMode.current = 1;
    }
  }, [chatMode]);

  // 初始化消息
  useEffect(() => {
    // 获取本地存储的回复模式
    let mode = JSON.parse(localStorage.getItem("reply_mode"));
    if (mode) {
      setReplyMode(mode);
    } else {
      setReplyMode("quick");
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    //先获取助手线程
    const assistantId = selectedEmployee.id;
    const localAssistantId = localStorage.getItem("assistant_id");
    if (assistantId !== localAssistantId) {
      localStorage.setItem("assistant_id", assistantId);
    }

    let thread = JSON.parse(localStorage.getItem(`${assistantId}-thread_id`));
    if (thread) {
      setThreadId(thread);
      getMessageList(thread).then(() => {
        scrollToBottom(); // 初始加载时滚动到底部
      });
    } else {
      setMessages([]);
      newThreadHandler().then((threadId) => {
        scrollToBottom(); // 新建线程后滚动到底部
      });
    }
    // eslint-disable-next-line
  }, [selectedEmployee]);

  //控制气泡出现是否滑动
  useEffect(() => {
    if (!isUserScrolling) {
      scrollToBottom();
    }
  }, [messages, isUserScrolling]);

  // 更改回复模式
  // const changeReplyMode = (mode) => {
  //   setReplyMode(mode);
  //   localStorage.setItem("reply_mode", JSON.stringify(mode));
  // };

  // 获取消息列表，增加保护机制
  const getMessageList = async (threadId) => {
    try {
      const data = await getThreadMessages(threadId);
      if (!data || !data.data) {
        console.log("获取消息列表失败");
      }

      const messages = data.data;

      // 获取图片内容并生成URL
      const imagePromises = messages.map(async (msg) => {
        if (!msg || !msg.content) return;

        // 遍历消息中的内容
        const contentPromises = msg.content.map(async (item) => {
          if (
            item.type === "image_file" &&
            item.image_file &&
            item.image_file.file_id
          ) {
            try {
              const blob = await getPictureContentApi(item.image_file.file_id);
              if (blob) {
                const imageUrl = URL.createObjectURL(blob);
                // 更新图片的URL到状态中
                setImageUrls((prev) => ({
                  ...prev,
                  [item.image_file.file_id]: imageUrl,
                }));
              }
            } catch (error) {
              console.error(
                `获取图片内容失败: ${item.image_file.file_id}`,
                error,
              );
            }
          }
        });

        return Promise.all(contentPromises); // 等待所有内容异步操作完成
      });

      await Promise.all(imagePromises); // 确保所有图片获取操作都完成
      setMessages(messages); // 更新消息列表
      return messages;
    } catch (error) {
      console.error("获取消息列表失败:", error);
      Message.error("获取消息列表失败，请稍后再试。"); // 显示错误信息给用户
    }
  };

  // 渲染最后一句回复
  const getMessageReply = async (threadId) => {
    if (replyMode === "quick") {
      let new_messages = await getMessageList(threadId);
      setIsLoadingChat(false);
      return new_messages;
    } else {
      const data = await getThreadMessages(threadId);
      const messages = data.data;
      setIsLoadingChat(false);
      const lastMessage = messages[0]; // 获取第一条消息

      if (lastMessage.content[0].type === "text") {
        // 按句号分割成多句话
        const sentences = lastMessage.content[0].text.value
          .split("。")
          .filter((sentence) => sentence.trim().length > 0);

        let accumulatedText = ""; // 初始化累积的文本内容

        // 遍历句子，并逐步更新消息内容
        sentences.forEach((sentence, index) => {
          let delay = sentence.length * 250;
          // console.log(sentence, sentence.length, delay);
          if (index === 0) {
            delay = 0;
          }
          setTimeout(() => {
            // 累积当前句子
            accumulatedText += sentence + "。";

            // 创建 messages 的副本，保持不变性
            const updatedMessages = [...messages];
            updatedMessages[0] = {
              ...updatedMessages[0],
              content: [
                {
                  ...updatedMessages[0].content[0],
                  text: {
                    ...updatedMessages[0].content[0].text,
                    value: accumulatedText, // 置为累积的文本内容
                  },
                },
              ],
            };
            // 更新状态
            setMessages(updatedMessages);
          }, delay); // 每句话的加入间隔1秒
        });
      }
    }
  };

  // 在对话新建时
  const newThreadHandler = async () => {
    const assistantId = localStorage.getItem("assistant_id");
    await createThread().then((data) => {
      if (data) {
        console.log("新建线程成功:", data.id);
        setThreadId(data.id);
        localStorage.removeItem(`${assistantId}-thread_id`);
        localStorage.setItem(
          `${assistantId}-thread_id`,
          JSON.stringify(data.id),
        );
        setMessages([]);
        handleSend(data.id); // 传入新的 threadId
        return data.id;
      }
    });
  };

  // 复制消息
  const handleCopy = (content) => {
    //复制当前内容
    navigator.clipboard.writeText(content);
  };

  // 删除消息
  const handleDeleteMessage = async (msg) => {
    //删除当前的一句对话
    setIsUserScrolling(true);
    if (msg.content[0].type === "image_file") {
      await deleteFileAPi(msg.content[0].image_file.file_id);
    }
    await deleteMessageApi(threadId, msg.id).then((data) => {
      setMessages((prevMessages) =>
        prevMessages.filter((message) => message.id !== msg.id),
      );
    });
  };

  // 删除消息并刷新
  const handleRefreshMessage = async (msg, msgIndex) => {
    try {
      setIsUserScrolling(true);

      const endIndex = messages.length - 1 - msgIndex;

      // 生成删除消息的Promise数组
      const deletePromises = [];
      for (let i = 0; i <= endIndex; i++) {
        if (messages[i].content[0].type === "image_file") {
          deletePromises.push(
            deleteFileAPi(messages[i].content[0].image_file.file_id),
          );
        }
        deletePromises.push(deleteMessageApi(threadId, messages[i].id));
      }

      // 等待所有删除操作完成
      await Promise.all(deletePromises);

      // 获取最新的消息列表
      const updatedMessages = await getMessageReply(threadId);

      if (updatedMessages.length > 0) {
        if (updatedMessages[0].role === "assistant") {
          if (updatedMessages[1].role === "user") {
            await updateMessageApi(threadId, updatedMessages[1].id, {
              process: "false",
            }).then(async () => {
              setIsLoadingChat(true);
              getMessageReply(threadId).then(() => {
                setIsLoadingChat(true);
                postRunByThreadBotCloud(
                  threadId,
                  draftInstructions,
                  salesMode.current,
                  selectedEmployee.id,
                  chatBackground,
                ).then(async (data) => {
                  setIsLoadingChat(true);
                  await handleBotResponse(
                    data,
                    threadId,
                    chatBackground,
                    handleBackgroundChange,
                  );
                });
              });
            });
          }
        } else {
          await updateMessageApi(threadId, updatedMessages[0].id, {
            process: "false",
          }).then(async () => {
            setIsLoadingChat(true);
            getMessageReply(threadId).then(() => {
              setIsLoadingChat(true);
              postRunByThreadBotCloud(
                threadId,
                draftInstructions,
                salesMode.current,
                selectedEmployee.id,
                chatBackground,
              ).then(async (data) => {
                setIsLoadingChat(true);
                await handleBotResponse(
                  data,
                  threadId,
                  chatBackground,
                  handleBackgroundChange,
                );
              });
            });
          });
        }
      } else {
        setIsLoadingChat(true);
        postRunByThreadBotCloud(
          threadId,
          draftInstructions,
          salesMode.current,
          selectedEmployee.id,
          chatBackground,
        ).then(async (data) => {
          setIsLoadingChat(true);
          await handleBotResponse(
            data,
            threadId,
            chatBackground,
            handleBackgroundChange,
          );
        });
      }
    } catch (error) {
      console.error("处理刷新消息时发生错误:", error);
      Message.error("刷新消息时发生错误，请稍后再试。");
    } finally {
      setIsUserScrolling(false);
    }
  };

  // 发送消息
  const handleSend = async (threadId) => {
    try {
      if (inputValue.trim() !== "") {
        setIsUserScrolling(false);
        setInputValue("");
        setIsLoadingChat(true);

        // 发送用户消息
        await postMessageByThreadBot(threadId, "user", inputValue, null);
        await getMessageList(threadId);
      } else {
        if (messages.length > 1) {
          if (messages[0].role === "assistant") {
            if (messages[1].role === "user") {
              await updateMessageApi(threadId, messages[1].id, {
                process: "false",
              }).then(async () => {});
            }
          } else {
            await updateMessageApi(threadId, messages[0].id, {
              process: "false",
            }).then(async () => {});
          }
        }
        if (messages.length === 1) {
          await updateMessageApi(threadId, messages[0].id, {
            process: "false",
          }).then(async () => {});
        }
      }

      // 无论是否有输入，都执行以下操作
      setIsLoadingChat(true);
      await postRunByThreadBotCloud(
        threadId,
        draftInstructions,
        salesMode.current,
        selectedEmployee.id,
        chatBackground,
      ).then(async (data) => {
        await handleBotResponse(
          data,
          threadId,
          chatBackground,
          handleBackgroundChange,
        );
      });

    } catch (error) {
      console.log("Error during message send:", error);
      console.error("Error during message send:", error);
      Message.error("发送消息时出错，请稍后再试。"); // 显示错误信息给用户
    }
  };

  const handleBotResponse = async (
    data,
    threadId,
    chatBackground,
    handleBackgroundChange,
  ) => {
    if (!data.result) {
      setIsLoadingChat(false);
      return false;
    }

    try {
      // 处理定时回复
      if (data.second) {
        await scheduleMessageReply(threadId, data.second);
      }

      if (data.new_background) {
        await handleBackgroundChange(data.new_background);
      } else if (data.memory) {
        const newBackground = chatBackground + "\n" + data.memory;
        await handleBackgroundChange(newBackground);
      }

      await getMessageReply(threadId);
      return true;
    } catch (processingError) {
      console.error("Error processing bot response:", processingError);
      Message.warning("处理响应时发生错误，但会话仍在继续");
      return false;
    }
  };

  // 清理定时器
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  //设置定时器
  const scheduleMessageReply = (threadId, seconds) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      console.log("定时器来了");
      getMessageReply(threadId).then((r) => {});
    }, seconds * 1000);
  };

  // 上传图片
  const handleUploadPic = (currentFile) => {
    setFile({
      ...currentFile,
      url: URL.createObjectURL(currentFile.originFile),
    });

    if (currentFile.status === "uploading") {
      uploadPictureFileAPi(currentFile.originFile).then((data) => {
        postMessageByThreadBot(threadId, "user", inputValue, data).then(
          (data) => {
            getMessageList(threadId).then((data) => {
              setFile(null);
              postRunByThreadBotCloud(
                threadId,
                draftInstructions,
                salesMode.current,
                selectedEmployee.id,
                chatBackground,
              ).then((data) => {
                handleBotResponse(
                  data,
                  threadId,
                  chatBackground,
                  handleBackgroundChange,
                ).then((r) => {});
              });
            });
          },
        );
      });
    }
  };

  const cos = useMemo(
    () =>
      new COS({
        getAuthorization: function (options, callback) {
          getCOSDownLoadTokenApi(userInfo.user_id)
            .then((response) => {
              if (response.status !== "success") {
                console.error("Failed to get COS download token:", response);
                Message.error("获取COS下载凭证失败");
                return;
              }
              const data = response.data;
              cosData.current = data;
              if (
                !data ||
                !data.temp_key ||
                !data.temp_secret ||
                !data.bucket ||
                !data.region
              ) {
                console.error("Invalid COS download token data:", data);
                Message.error("获取COS下载凭证失败，数据无效");
                return;
              }

              const currentTime = Math.floor(Date.now() / 1000); // 当前时间（秒）

              callback({
                TmpSecretId: data.temp_key, // 临时密钥的 tmpSecretId
                TmpSecretKey: data.temp_secret, // 临时密钥的 tmpSecretKey
                SecurityToken: data.token, // 临时密钥的 sessionToken
                StartTime: currentTime - 60, // 减少60秒作为缓冲
                ExpiredTime: data.expiration, // 临时密钥失效时间戳
                ScopeLimit: true, // 细粒度控制权限
              });
            })
            .catch((error) => {
              console.error("Error fetching COS download token:", error);
              Message.error("获取COS下载凭证失败");
            });
        },
      }),
    [userInfo.user_id],
  );

  // 点击下载按钮之后的操作
  const getCOSDownLoadToken = async (sentence, fileName) => {
    const regex = /(?:https:\/\/.+\/)([^/]+)\/(.+)/;
    const match = sentence.match(regex);
    try {
      const res = await getCOSDownLoadTokenApi(userInfo.user_id);

      if (!res || res.status !== "success" || !res.data) {
        console.error("获取下载凭证失败:", res);
        Message.error("获取下载凭证失败");
        return;
      }

      cos.getObject(
        {
          Bucket: res.data.bucket,
          Region: res.data.region,
          Key: `${userInfo.user_id}/${match[2]}`,
          DataType: "blob",
        },
        function (err, data) {
          if (err) {
            console.error("下载出错:", err);
            Message.error(`下载文件失败: ${err.message}`);
          } else {
            if (!data || !data.Body) {
              console.error("下载的文件为空或无效:", data);
              Message.error("下载的文件为空或无效");
              return;
            }
            Message.success("下载文件成功");

            // 处理下载的文件，生成下载链接
            const blob = new Blob([data.Body]);
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url); // 释放内存
          }
        },
      );
    } catch (error) {
      console.error("获取下载凭证时发生错误:", error);
      Message.error("获取下载凭证失败");
    }
  };

  const getCOSDownLoadPicToken = async (sentence) => {
    const regex = /(?:https:\/\/.+\/)([^/]+)\/(.+)/;
    const match = sentence.match(regex);
    return new Promise((resolve, reject) => {
      cos.getObjectUrl(
        {
          Bucket: "zchat-1256349444",
          Region: "ap-guangzhou",
          Key: `${userInfo.user_id}/${match[2]}`,
          Sign: true,
        },
        async function (err, data) {
          if (err) return console.log(err);
          var url = data.Url;
          var downloadUrl =
            url +
            (url.indexOf("?") > -1 ? "&" : "?") +
            "response-content-disposition=attachment";

          const response = await fetch(downloadUrl);
          const blob = await response.blob();
          const reader = new FileReader();
          reader.onloadend = () => {
            const base64data = reader.result;
            localforage.setItem(match[0], base64data);
            resolve(base64data);
          };
          reader.readAsDataURL(blob);
        },
      );
    });
  };

  //判断链接是哪种类型
  const isCard = (sentence) => {
    const picRegex =
      /(https:\/\/zchat-[\w-]+\.cos\.[\w-]+\.myqcloud\.com\/[^/]+\/[^/]+\.(png|jpg|jpeg))/;
    const fileRegex =
      /(https:\/\/zchat-[\w-]+\.cos\.[\w-]+\.myqcloud\.com\/[^/]+\/[^/]+\.(pdf|doc|docx|pptx))/;
    if (fileRegex.test(sentence)) {
      return "file";
    } else if (picRegex.test(sentence)) {
      return "pic";
    } else {
      return "normal";
    }
  };

  // 实际msg为链接的渲染卡片
  const msgDisplayCard = (sentence) => {
    // 提取文件链接 - 更新正则表达式以支持更广泛的字符集
    const fileRegex =
      /(https:\/\/zchat-[\w-]+\.cos\.[\w-]+\.myqcloud\.com\/[^/]+\/[^/]+\.(pdf|doc|docx|pptx))/;
    const match = sentence.match(fileRegex);
    if (!match) return null;
    if (match) {
      const fileName = decodeURIComponent(match[0].split("/").pop());
      return (
        <Card
          hoverable
          style={{
            width: 300,
            height: 50,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Space align="center">
            <IconFile style={{ fontSize: 30, color: "#8bc34a" }} />
            <div style={{ fontSize: 16, fontWeight: "bold" }}>{fileName}</div>
            <Button
              type={"text"}
              style={{ color: "#165DFF" }}
              onClick={() => {
                getCOSDownLoadToken(sentence, fileName);
              }}
            >
              下载
            </Button>
          </Space>
        </Card>
      );
    }
  };

  return (
    <Space
      direction="vertical"
      style={{
        height: "100vh",
        boxShadow: "-2px 0 2px rgba(0, 0, 0, 0.05)",
        paddingLeft: 10,
      }}
    >
      <Space
        style={{
          width: "30vw",
          height: 40,
          marginTop: 8,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h3>聊天测试</h3>
        {/*<Tabs type="text" activeTab={replyMode} onChange={changeReplyMode}>*/}
        {/*  <TabPane key="personal" title="拟人回复" />*/}
        {/*  <TabPane key="quick" title="快速回复" />*/}
        {/*</Tabs>*/}
        <Tag color="green" icon={<IconWechat />}>
          <h3>{selectedEmployee.name}</h3>
        </Tag>
        <LaunchModal
          selectedEmployee={selectedEmployee}
          draftInstructions={draftInstructions}
          chatMode={chatMode}
        />
      </Space>

      <Space direction="vertical">
        <Space
          direction="vertical"
          style={{
            width: "30vw",
            height: "82vh",
            overflowY: "auto",
            scrollbarWidth: "thin",
            scrollbarColor: "#EEE white",
          }}
          ref={contentRef}
        >
          {messages.length === 0 ? (
            <Empty
              description="暂无聊天数据"
              style={{
                marginTop: "30vh",
              }}
            />
          ) : (
            messages
              .slice()
              .reverse()
              .map((msg, index) => {
                return (
                  <Space key={index} align={"start"}>
                    <Avatar>
                      <img
                        alt="avatar"
                        src={
                          msg.role === "user"
                            ? "https://pic1.zhimg.com/70/v2-53c1bc420d925bbc55d3ebddc1f1a091_1440w.avis?source=172ae18b&biz_tag=Post"
                            : "https://pic1.zhimg.com/70/v2-73543239e7ae941adf7d3a4d62385951_1440w.avis?source=172ae18b&biz_tag=Post"
                        }
                      />
                    </Avatar>
                    <Space direction={"vertical"}>
                      <Space direction="horizontal">
                        <Text>{msg.role === "user" ? "用户" : "销售"}</Text>
                        <Text type="secondary" style={{ fontSize: "12px" }}>
                          {formatTimestampToDateTime(msg.created_at)}
                        </Text>
                        <Text style={{ fontSize: "12px", color: "#366ef4" }}>
                          {msg.role === "assistant" &&
                            index > 0 &&
                            `${Math.abs(msg.created_at - msg.metadata.created_time)}s`}
                        </Text>
                        {msg.role === "user" && msg.metadata.manual && (
                          <Tag color={"red"} size={"small"}>
                            触发人工
                          </Tag>
                        )}
                      </Space>
                      <Space direction={"vertical"}>
                        {msg.content.map((item, contentIndex) => {
                          if (
                            item.type === "text" &&
                            item.text &&
                            item.text.value
                          ) {
                            return (
                              <React.Fragment key={`${msg.id}-${contentIndex}`}>
                                {item.text.value
                                  .split(/(?<![?？])([?？!。\n])/g)
                                  .reduce((acc, curr, i, arr) => {
                                    // 如果当前片段后面是?，且再后面以'开头，则组合它们
                                    if (
                                      i % 2 === 0 &&
                                      arr[i + 1] === "?" &&
                                      arr[i + 2]?.startsWith("'")
                                    ) {
                                      acc.push(curr + "?" + arr[i + 2]);
                                      return acc;
                                    }
                                    // 跳过已经被组合的片段
                                    if (
                                      i % 2 === 0 &&
                                      arr[i - 1] === "?" &&
                                      curr.startsWith("'")
                                    ) {
                                      return acc;
                                    }
                                    // 正常添加其他片段
                                    if (i % 2 === 0) {
                                      acc.push(curr + (arr[i + 1] || ""));
                                    }
                                    return acc;
                                  }, [])
                                  .filter(
                                    (sentence) => sentence.trim().length > 0,
                                  ) // 过滤掉空句子
                                  .map((sentence, sentenceIndex) => {
                                    const key = messageType[sentence];
                                    const renderComponent = msgMap[key];
                                    if (renderComponent)
                                      return (
                                        <Space
                                          key={`${msg.id}-${contentIndex}-${sentenceIndex}`}
                                          align={"center"}
                                        >
                                          {key !== "normal" &&
                                            renderComponent(sentence)}

                                          {key === "normal" && (
                                            <Text
                                              style={{
                                                background:
                                                  msg.role === "user"
                                                    ? "#366ef4"
                                                    : "#f7f7fa",
                                                color:
                                                  msg.role === "user"
                                                    ? "#000000"
                                                    : "#ffffff",
                                                padding: "10px",
                                                borderRadius: "8px",
                                                backgroundColor: msg.isTyping
                                                  ? "#65B0F4"
                                                  : msg.role === "user"
                                                    ? "#F7F7FA"
                                                    : "#366EF4",
                                                display: "inline-block",
                                              }}
                                            >
                                              {msg.role === "user"
                                                ? sentence // 用户消息保留所有标点
                                                : sentence.replace(
                                                    /[？?！!。，,；;、]+$/g,
                                                    "",
                                                  )}
                                            </Text>
                                          )}
                                          {/*{isCard(sentence) === "pic" ? (*/}
                                          {/*  <img*/}
                                          {/*    src={sysUrls[sentence]}*/}
                                          {/*    alt={"系统图片"}*/}
                                          {/*    style={{*/}
                                          {/*      width: "250px",*/}
                                          {/*      height: "auto",*/}
                                          {/*    }}*/}
                                          {/*  />*/}
                                          {/*) : isCard(sentence) === "file" ? (*/}
                                          {/*  msgDisplayCard(sentence)*/}
                                          {/*) : (*/}
                                          {/*  <Text*/}
                                          {/*    style={{*/}
                                          {/*      background:*/}
                                          {/*        msg.role === "user"*/}
                                          {/*          ? "#366ef4"*/}
                                          {/*          : "#f7f7fa",*/}
                                          {/*      color:*/}
                                          {/*        msg.role === "user"*/}
                                          {/*          ? "#000000"*/}
                                          {/*          : "#ffffff",*/}
                                          {/*      padding: "10px",*/}
                                          {/*      borderRadius: "8px",*/}
                                          {/*      backgroundColor: msg.isTyping*/}
                                          {/*        ? "#65B0F4"*/}
                                          {/*        : msg.role === "user"*/}
                                          {/*          ? "#F7F7FA"*/}
                                          {/*          : "#366EF4",*/}
                                          {/*      display: "inline-block",*/}
                                          {/*    }}*/}
                                          {/*  >*/}
                                          {/*    {msg.role === "user"*/}
                                          {/*      ? sentence // 用户消息保留所有标点*/}
                                          {/*      : sentence.replace(*/}
                                          {/*          /[？?！!。，,；;、]+$/g,*/}
                                          {/*          "",*/}
                                          {/*        )}*/}
                                          {/*  </Text>*/}
                                          {/*)}*/}
                                          <Space>
                                            {msg.role !== "user" && (
                                              <Button
                                                size="mini"
                                                icon={<IconCopy />}
                                                onClick={() =>
                                                  handleCopy(
                                                    msg.role === "user"
                                                      ? sentence
                                                      : sentence.replace(
                                                          /[？?！!。，,；;、]+$/g,
                                                          "",
                                                        ),
                                                  )
                                                }
                                                type="text"
                                                style={{ color: "black" }}
                                              />
                                            )}
                                            {/* 如果是最后一个句子且是助手的消息才显示按钮 */}
                                            {sentenceIndex === 0 && (
                                              <Space>
                                                <Button
                                                  size="mini"
                                                  icon={<IconDelete />}
                                                  onClick={() =>
                                                    handleDeleteMessage(msg)
                                                  }
                                                  type="text"
                                                  style={{ color: "black" }}
                                                />
                                                {msg.role !== "user" && (
                                                  <Tooltip content="重新回答">
                                                    <Button
                                                      size="mini"
                                                      icon={<IconRefresh />}
                                                      onClick={() =>
                                                        handleRefreshMessage(
                                                          msg,
                                                          index,
                                                        )
                                                      }
                                                      type="text"
                                                      style={{ color: "black" }}
                                                    />
                                                  </Tooltip>
                                                )}
                                              </Space>
                                            )}
                                          </Space>
                                        </Space>
                                      );
                                    return null;
                                  })}
                              </React.Fragment>
                            );
                          } else if (
                            item.type === "image_file" &&
                            imageUrls[item.image_file.file_id]
                          ) {
                            return (
                              <Space key={item.image_file.file_id}>
                                <Image
                                  src={imageUrls[item.image_file.file_id]}
                                  alt="用户图片"
                                  width={250}
                                  style={{ height: "auto" }}
                                />
                                <Button
                                  size="mini"
                                  icon={<IconDelete />}
                                  onClick={() => handleDeleteMessage(msg)}
                                  type="text"
                                  style={{ color: "black" }}
                                ></Button>
                              </Space>
                            );
                          }
                          return null;
                        })}
                      </Space>
                    </Space>
                  </Space>
                );
              })
          )}
          {isLoadingChat && <Spin dot style={{ marginLeft: "50px" }} />}
        </Space>

        <Space style={{ marginTop: "2vh", paddingLeft: 4 }}>
          <Tooltip content="清空对话记录">
            <Button
              type="text"
              shape="circle"
              onClick={newThreadHandler}
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                >
                  <path d="M2 6h4V2.333C6 1.597 6.597 1 7.334 1h1.333C9.403 1 10 1.597 10 2.333V6h4c.737 0 1.334.597 1.334 1.333V14c0 .736-.597 1.333-1.334 1.333H2A1.333 1.333 0 0 1 .667 14V7.333C.667 6.597 1.264 6 2 6Zm5.334-3.667v4a1 1 0 0 1-1 1H2v1.334h12V7.333H9.667a1 1 0 0 1-1-1v-4H7.334ZM2 10v4h2.667v-1.667a.667.667 0 0 1 1.333 0V14h1.334v-2a.667.667 0 1 1 1.333 0v2H10v-1.667a.667.667 0 0 1 1.334 0V14H14v-4H2Z" />
                </svg>
              }
            />
          </Tooltip>
          <Input
            style={{
              height: "5vh",
              width: "calc(30vw - 100px)",
              marginLeft: 4,
            }}
            disabled={disableValue.current}
            value={inputValue}
            onChange={(value) => setInputValue(value)}
            onPressEnter={() => handleSend(threadId)}
            suffix={
              <Upload
                showUploadList={false}
                fileList={file ? [file] : []}
                multiple={false}
                onChange={(_, currentFile) => handleUploadPic(currentFile)}
                accept=".png,.jpeg,.jpg"
              >
                <Button
                  type="text"
                  size={"mini"}
                  icon={<IconFileImage />}
                  style={{ color: "black" }}
                ></Button>
              </Upload>
              // <Popover
              //          size="small"
              //          content={
              //              <div
              //                  style={{height: 250, minWidth: 200, textAlign: "center", display: "flex"}}>
              //                  <Space direction={"vertical"} style={{width: "100%"}} align={"center"}>
              //                      {pic && pic.url ?
              //                          <img
              //                              src={pic.url}
              //                              alt="预览图片"
              //                              style={{width: 150}}
              //                          /> :
              //                          <Result
              //                              title='暂无图片'
              //                          ></Result>}
              //                      <Button
              //                          type={'text'}
              //                          icon={<IconCloseCircle/>}
              //                          style={{
              //                              display: pic && pic.url ? 'inline' : 'none'
              //                          }}
              //                          shape="circle"
              //                          onClick={() => setPic({})}
              //                      />
              //                  </Space>
              //              </div>
              //          }>
              //
              // </Popover>
            }
          />
          <Tooltip content="发送聊天">
            <Button
              type={inputValue === "" ? "text" : "text"}
              icon={
                <IconSend
                  style={{
                    width: "20",
                    height: "20",
                    marginLeft: 10,
                    marginTop: 3,
                  }}
                />
              }
              onClick={() => handleSend(threadId)} // 传入当前的 threadId
              disabled={inputValue === ""}
            />
          </Tooltip>
        </Space>
      </Space>
    </Space>
  );
};

export default ChatTest;
