// src/pages/MainLayout.js
import React, { useState, useEffect } from "react";
import {
  Avatar,
  Button,
  Layout,
  Menu,
  Popover,
  Space,
  Typography,
} from "@arco-design/web-react";
import {
  IconBook,
  IconMenuFold,
  IconMenuUnfold,
  IconMessage,
  IconUserGroup,
  IconDashboard,
} from "@arco-design/web-react/icon";
import { useNavigate, Outlet } from "react-router-dom";

const { Content, Sider } = Layout;

const MainLayout = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [username, setUsername] = useState(""); // 当前登录的用户账号名
  const navigate = useNavigate();

  useEffect(() => {
    // 从本地存储获取 username
    const storedUser = localStorage.getItem("user_info");
    if (storedUser) {
      const { username: storedUsername } = JSON.parse(storedUser);
      setUsername(storedUsername);
    }
  }, []);

  const handleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const handleLogout = () => {
    // 清除本地存储的 token
    localStorage.removeItem("sales_token");
    // 跳转到登录页
    navigate("/login");
  };

  const handleMenuClick = (key) => {
    // console.log(key);
    navigate(`/${key}`);
  };

  const getSelectedMenuKey = () => {
    const path = window.location.pathname;
    if (path === "/" || path === "/messages") return "messages";
    if (path.startsWith("/employees")) return "employees";
    if (path.startsWith("/files")) return "files";
    if (path.startsWith("/statistics")) return "statistics";
    return "messages";
  };

  return (
    <Layout style={{ height: "100vh", width: "100%" }}>
      <Sider
        collapsed={collapsed}
        onCollapse={handleCollapsed}
        collapsible
        style={{ height: "100vh", marginRight: 15 }}
        width={"150px"}
        trigger={
          collapsed ? (
            <Button
              style={{ marginBottom: 20 }}
              size={"small"}
              icon={<IconMenuUnfold style={{ fontSize: 20, marginTop: 3 }} />}
            />
          ) : (
            <Space style={{ marginBottom: 20 }}>
              <Popover
                position="top"
                content={
                  <Button type={"outline"} onClick={handleLogout}>
                    退出登录
                  </Button>
                }
              >
                <Space>
                  <Avatar style={{ backgroundColor: "#3370ff" }} size={30}>
                    {username ? username.charAt(0).toUpperCase() : ""}
                  </Avatar>
                  <Typography.Text style={{ margin: 0, fontSize: 12 }}>
                    {username || ""}
                  </Typography.Text>
                </Space>
              </Popover>
              <Button
                size={"small"}
                icon={<IconMenuFold style={{ fontSize: 20, marginTop: 3 }} />}
              />
            </Space>
          )
        }
      >
        <Space size="large" style={{ height: 48, marginTop: 7 }}>
          <img
            src={`${process.env.PUBLIC_URL}/logo.svg`}
            alt="logo"
            style={{ marginLeft: 12 }}
          />
        </Space>
        <Menu
          onClickMenuItem={handleMenuClick}
          defaultSelectedKeys={[getSelectedMenuKey()]}
        >
          <Menu.Item key="messages">
            <IconMessage style={{ fontSize: 18, marginLeft: -1 }} />
            消息列表
          </Menu.Item>
          <Menu.Item key="employees">
            <IconUserGroup style={{ fontSize: 18, marginLeft: -1 }} />
            员工列表
          </Menu.Item>
          <Menu.Item key="files">
            <IconBook style={{ fontSize: 18, marginLeft: -1 }} />
            销售资料
          </Menu.Item>
          <Menu.Item key="statistics">
            <IconDashboard style={{ fontSize: 18, marginLeft: -1 }} />
            数据统计
          </Menu.Item>
          {/*<Menu.SubMenu*/}
          {/*  key="statistics"*/}
          {/*  title={*/}
          {/*    <>*/}
          {/*      <IconDashboard style={{ fontSize: 18, marginLeft: -1 }} />*/}
          {/*      数据统计*/}
          {/*    </>*/}
          {/*  }*/}
          {/*>*/}
          {/*  <Menu.Item key="normal">常规监控</Menu.Item>*/}
          {/*  <Menu.Item key="chat_1">聊天1</Menu.Item>*/}
          {/*  <Menu.Item key="chat_2">聊天2</Menu.Item>*/}
          {/*</Menu.SubMenu>*/}
        </Menu>
      </Sider>
      <Content>
        <Outlet />
      </Content>
    </Layout>
  );
};

export default MainLayout;
