// src/pages/AgentList/ListPage/ListPage.js

import React from 'react';
import EmployeeTable from './component/EmployeeTable';

export const ListPage = ({ setSelectedEmployee }) => {
    return (
        <div style={{ width: "100%" }}>
            <EmployeeTable setSelectedEmployee={setSelectedEmployee} />
        </div>
    )
}