// AssistantInfo.js
import React, {useEffect, useState} from "react";
import {Space, Tag, Radio, Input, Message} from "@arco-design/web-react";
import ProductList from "../../SalesMaterial/components/ProductList";
import CaseMaterial from "../../SalesMaterial/components/CaseMaterialTable";
import {
    getThreadBackgroundApi,
    putThreadBackgroundApi,
} from "../../../api/normalApi";

const TextArea = Input.TextArea;

const AssistantInfo = ({assistant, threadId}) => {
    const [products, setProducts] = useState([]);
    const [cases, setCases] = useState([]);
    const [materialTab, setMaterialTab] = useState("用户画像");
    const [roleInfo, setRoleInfo] = useState();
    const [background, setBackground] = useState();

    // 修改当前线程背景信息
    const updateThreadBackground = () => {
        putThreadBackgroundApi(threadId, {background: background}).then((res) => {
            if (res && res.status === "success") {
                Message.success("修改用户画像成功！");
            } else {
                console.log(res);
            }
        });
    };

    useEffect(() => {
        if (threadId) {
            getThreadBackgroundApi(threadId).then((res) => {
                if (res && res.status === "success") {
                    setBackground(res.data.background);
                }
            });

        }
    }, [threadId]);

    useEffect(() => {
        if (assistant && assistant.role_instructions) {
            setRoleInfo(assistant.role_instructions);
        }
    }, [assistant]);

    return (
        <Space direction="vertical" style={{marginTop:1}}>
            <Space>
                <Radio.Group
                    type="button"
                    name="tab"
                    value={materialTab}
                    onChange={setMaterialTab}
                    options={["用户画像", "员工信息", "销售资料"]}
                >
                </Radio.Group>
                <Tag color={"green"} size={"large"}>
                    数字员工: {assistant.name}
                </Tag>
                {/*<h3 style={{margin:0}}>{assistant.name}</h3>*/}
            </Space>
            {materialTab === "用户画像" && (
                <TextArea
                    value={background}
                    onChange={(v) => setBackground(v)}
                    onBlur={updateThreadBackground}
                    style={{
                        width: "31vw", height: "87vh", overflowY: "auto",
                        scrollbarWidth: "thin",
                        scrollbarColor: "#EEE white",
                    }}
                ></TextArea>
            )}
            {materialTab === "员工信息" && (
                <TextArea
                    value={roleInfo}
                    style={{
                        width: "31vw", height: "87vh", overflowY: "auto",
                        scrollbarWidth: "thin",
                        scrollbarColor: "#EEE white",
                    }}
                ></TextArea>
            )}
            {materialTab === "销售资料" && (
                <Space direction={"vertical"} style={{
                    height: "87vh", overflowY: "auto",
                    scrollbarWidth: "thin",
                    scrollbarColor: "#EEE white",
                }}>
                    <ProductList
                        columns={["name", "price", "description", "actions"]}
                        assistant_id={assistant.id}
                        products={products}
                        setProducts={setProducts}
                    />
                    <CaseMaterial
                        columns={["case_name", "description", "actions"]}
                        assistant_id={assistant.id}
                        cases={cases}
                        setCases={setCases}
                    />
                </Space>
            )}
        </Space>
    );
};

export default AssistantInfo;
