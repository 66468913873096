import React, { useRef, useEffect, useCallback } from 'react';
import * as echarts from 'echarts';

const EChartsComponent = ({ option, style = {} }) => {
    const chartRef = useRef(null);
    const chartInstance = useRef(null);

    // 初始化图表的函数
    const initChart = useCallback(() => {
        const chart = echarts.getInstanceByDom(chartRef.current);
        if (chart) {
            chartInstance.current = chart;
        } else {
            chartInstance.current = echarts.init(chartRef.current);
        }
    }, []);

    // 更新图表的函数
    const updateChart = useCallback(() => {
        if (!chartInstance.current) {
            return;
        }

        try {
            chartInstance.current.setOption(option, true);
        } catch (error) {
            console.error('Failed to update chart:', error);
        }
    }, [option]);

    // 处理窗口大小变化
    const handleResize = useCallback(() => {
        if (chartInstance.current) {
            chartInstance.current.resize();
        }
    }, []);

    // 初始化和清理
    useEffect(() => {
        if (!chartRef.current) {
            return;
        }

        // 确保元素已经渲染完成
        setTimeout(() => {
            initChart();
            updateChart();
        }, 0);

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
            if (chartInstance.current) {
                chartInstance.current.dispose();
                chartInstance.current = null;
            }
        };
    }, []);

    // 处理 option 变化
    useEffect(() => {
        if (chartRef.current && option) {
            // 确保图表实例存在
            if (!chartInstance.current) {
                initChart();
            }
            // 更新图表
            updateChart();
        }
    }, [option, initChart, updateChart]);

    return (
        <div
            ref={chartRef}
            style={{
                width: '100%',
                height: '100%',
                minHeight: '500px',
                backgroundColor: '#fff',
                ...style
            }}
        />
    );
};

export default EChartsComponent;