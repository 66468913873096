import React, {useEffect, useRef, useState} from "react";
import {
    Input,
    Button,
    Space,
    Message,
} from "@arco-design/web-react";
import {
    updateAssistantApi,
    getAssistantsIdApi
} from "../../../api/normalApi";
import ProductList from "../../SalesMaterial/components/ProductList";
import CaseMaterial from "../../SalesMaterial/components/CaseMaterialTable";
import ChatTest from "./ChatTest";
import {createOpenaiAssistant, createOpenaiVStore} from "../../../api/openaiAPI";

const {TextArea} = Input;

const EditPage = ({
                      draftInstructions,
                      setDraftInstructions,
                      roleInstructions,
                      setRoleInstructions,
                      selectedEmployee,
                      setSelectedEmployee,
                      setShowPart,
                      setSalesProcess,
                      salesProcess,
                  }) => {
    const [threadId, setThreadId] = useState("");
    const [editing, setEditing] = useState(false);
    const [name, setName] = useState("新员工");
    const [products, setProducts] = useState(null);
    const [cases, setCases] = useState(null);
    // const [scripts, setScripts] = useState([]);
    const inputRef = useRef(null);
    // eslint-disable-next-line
    const [chatMode, setChatMode] = useState(
        localStorage.getItem("chat_mode") || "sales",
    );
    const [chatBackground, setChatBackground] = useState("");

    // const [employee, setEmployee] = useState(null);

    // 当 threadId 变化时，加载对应的聊天背景
    useEffect(() => {
        if (threadId) {
            const savedBackground = localStorage.getItem(`chat_background_${threadId}`);
            setChatBackground(savedBackground || "");
        } else {
            setChatBackground("");
        }
    }, [threadId]);

    useEffect(() => {
        updateAssistant()
        // eslint-disable-next-line
    }, [cases, products])

    // 聚焦输入框
    useEffect(() => {
        if (editing && inputRef.current) {
            inputRef.current.focus();
        }
    }, [editing]);

    // 获取员工信息和产品列表
    useEffect(() => {
        if (selectedEmployee) {
            fetchAssistantData(selectedEmployee.id);
        }
        // eslint-disable-next-line
    }, [selectedEmployee]);


    // 处理聊天背景变更
    const handleBackgroundChange = (value) => {
        setChatBackground(value);
        // 将更改保存到 localStorage，使用 threadId 作为 key
        if (threadId) {
            localStorage.setItem(`chat_background_${threadId}`, value);
        }
    };


    // 获取员工数据并更新状态
    const fetchAssistantData = async (id) => {
        try {
            const response = await getAssistantsIdApi(id);
            if (response.status === "success") {
                const data = response.data;
                setName(data.name);
                setDraftInstructions(data.draft_instructions);
                setRoleInstructions(data.role_instructions);
                setSalesProcess(data.sales_process);
                // setEmployee(data);
                // console.log(data)

                let updateParams = {};
                let needUpdate = false;

                // 分别判断和创建
                if (data.openai_assistant_id === "") {
                    const assistantResponse = await createOpenaiAssistant({
                        instructions: "你是一个知识检索助手",
                        name: data.name,
                        model: "gpt-4o"
                    });
                    updateParams.openai_assistant_id = assistantResponse.id;
                    needUpdate = true;
                }

                if (data.openai_vs_id === "") {
                    const vectorStore = await createOpenaiVStore(data.name);
                    updateParams.openai_vs_id = vectorStore.id;
                    needUpdate = true;
                }

                // 如果有需要更新的内容，才进行更新
                if (needUpdate) {
                    await updateAssistantApi(id, updateParams);
                }
            } else {
                Message.error("获取员工信息失败");
            }
        } catch (error) {
            Message.error("请求失败，请重试");
            console.error(error);
        }
    };

    // 保存名称
    const handleSaveName = () => {
        if (!name.trim()) {
            Message.error("员工名不能为空");
            return;
        }
        updateAssistantApi(selectedEmployee.id, {name}).then(async (data) => {
            if (data.status === "success") {
                Message.success("名称已修改");
                fetchAssistantData(selectedEmployee.id);
            } else {
                Message.error("修改失败");
                console.log(data);
            }
        });
        setEditing(false);
    };


    const updateAssistant = async () => {
        // 检查 products 和 cases 是否为 null
        if (!products || !cases) {
            // console.log("products 或 cases 为 null，无法更新角色信息和销售流程");
            return;
        }

        // 构建新的 draftInstructions
        const productInstructions = products
            .map((item) => {
                return `${item.name} ${item.price}元 ${item.description} ${item.urls}`;
            })
            .join("\n");

        // console.log(cases)
        const casesInstructions = cases
            .map((item) => {
                if (item.is_internal) {
                    return `名称：${item.case_name} 描述：${item.description} 内部资料仅供知识检索`;
                }
                if (item.file_url !== '') {
                    return `名称：${item.case_name} 描述：${item.description} 链接：https://zchat-1256349444.cos.ap-guangzhou.myqcloud.com/${item.file_url}`;
                }
                return `名称：${item.case_name} 描述：${item.description}`
            })
            .join("\n");

        // console.log(casesInstructions)
        // 初始化 newDraftInstructions
        let newDraftInstructions = `${roleInstructions}\n\n`;

        // 仅当 productInstructions 不为空时添加相关内容
        if (productInstructions.trim() !== "") {
            newDraftInstructions += `我们的产品名称、价格、介绍以及链接如下：\n${productInstructions}\n\n`;
        }

        // 仅当 casesInstructions 不为空时添加相关内容
        if (casesInstructions.trim() !== "") {
            newDraftInstructions += `服务的参考资料名称、介绍、文件链接如下：\n${casesInstructions}\n\n`;
        }

        // 始终添加销售流程话术参考
        // newDraftInstructions += `产品销售流程话术参考：\n${salesProcess}`;

        setDraftInstructions(newDraftInstructions);
        // console.log(newDraftInstructions)

        const newEmployee = {
            draft_instructions: newDraftInstructions,
            role_instructions: roleInstructions,
            sales_process: salesProcess,
        };

        // console.log(newDraftInstructions);

        // 更新助理信息
        await updateAssistantApi(selectedEmployee.id, newEmployee);
    };

    // 将脚本数组转换为保存格式
    // const formatSalesProcess = () => {
    //     return scripts
    //         .map((script) => {
    //             return `*阶段* ${script.stage}\n*描述* ${script.description}\n*参考话术* ${script.script}`;
    //         })
    //         .join("\n\n");
    // };

    // 添加新脚本（在指定索引下方插入）
    // const handleAddScriptAtIndex = (index) => {
    //     const newScript = { stage: "", description: "", script: "" };
    //     const newScripts = [...scripts];
    //     newScripts.splice(index + 1, 0, newScript);
    //     setScripts(newScripts);
    // };
    //
    // // 删除脚本
    // const handleRemoveScript = (index) => {
    //     const newScripts = scripts.filter((_, i) => i !== index);
    //     // 确保至少有一个脚本存在
    //     if (newScripts.length === 0) {
    //         setScripts([{ stage: "", description: "", script: "" }]);
    //     } else {
    //         setScripts(newScripts);
    //     }
    // };

    // 保存脚本到后端（防抖处理）
    // useEffect(() => {
    //     if (selectedEmployee) {
    //         const timeout = setTimeout(() => {
    //             const formattedProcess = formatSalesProcess();
    //             setSalesProcess(formattedProcess);
    //             updateAssistant();
    //         }, 500); // 500ms 防抖
    //
    //         return () => clearTimeout(timeout);
    //     }
    //     // eslint-disable-next-line
    // }, [scripts]);

    // const changeReplyMode = (value) => {
    //     setChatMode(value);
    //     localStorage.setItem("chat_mode", value);
    // };


    return (
        <Space style={{
            height: "100vh", paddingRight: 8
        }} align={"start"}>
            <Space
                direction={"vertical"}
                style={{
                    height: "100vh",
                    scrollbarWidth: "thin",
                    scrollbarColor: "#EEE white",
                    // overflowY: "auto",
                    width: "33vw"
                }}
            >
                <Space
                    style={{
                        width: "100%",
                        height: 40,
                        marginTop: 8,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <h3 style={{margin: 0, marginRight: 10}}>员工信息</h3>
                    <Input
                        ref={inputRef}
                        value={name}
                        onChange={setName}
                        onBlur={handleSaveName}
                        onPressEnter={handleSaveName}
                        placeholder="未命名的新员工"
                        style={{flex: 1, marginRight: 10, minWidth: "10vw", textAlign: "center"}}
                    />
                    <Button
                        size="small"
                        type="outline"
                        onClick={() => setShowPart(false)}
                    >
                        返回
                    </Button>
                </Space>
                <TextArea
                    style={{
                        maxHeight: "94vh",
                        scrollbarWidth: "thin",
                        scrollbarColor: "#EEE white",
                        // overflowY: "auto",
                    }}
                    placeholder={"填写人物设定"}
                    autoSize={{minRows: 10}}
                    value={roleInstructions}
                    onChange={(v) => {
                        setRoleInstructions(v);
                    }}
                    onBlur={updateAssistant}
                />

            </Space>
            <ChatTest
                threadId={threadId}
                setThreadId={setThreadId}
                draftInstructions={draftInstructions}
                selectedEmployee={selectedEmployee}
                chatMode={chatMode}
                chatBackground={chatBackground}
                handleBackgroundChange={handleBackgroundChange}
            />
            <Space direction={"vertical"} style={{
                maxHeight: "94vh",
                scrollbarWidth: "thin",
                scrollbarColor: "#EEE white",
                overflowY: "auto",
            }}>
                <Space
                    style={{
                        width: "100%",
                        height: 40,
                        marginTop: 8,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <h3 style={{margin: 0}}>销售资料</h3>
                </Space>
                {selectedEmployee && (
                    <div>
                        <ProductList
                            columns={["name", "price", "actions"]}
                            assistant_id={selectedEmployee.id}
                            products={products}
                            setProducts={setProducts}
                            mode={"displayModal"}
                        />
                        <CaseMaterial
                            columns={["case_name", "actions", 'is_internal']}
                            selectedEmployee={selectedEmployee}
                            cases={cases}
                            setCases={setCases}
                            mode={"displayModal"}
                        />
                    </div>
                )}
                <h3 style={{margin: 0}}>记忆存储</h3>
                <TextArea
                    placeholder="AI自动整理聊天关键信息记忆"
                    value={chatBackground}
                    onChange={handleBackgroundChange}
                    autoSize={{minRows: 3}}
                    style={{width: "100%"}}
                    disabled={!threadId}  // 当没有 threadId 时禁用输入
                />
            </Space>
        </Space>
    );
};

export default EditPage;
