import React, { useEffect, useState } from "react";
import ChatList from "./Messages/ChatList";
import AssistantInfo from "./AssistantInfo/AssistantInfo";
import { Avatar, Space, Tabs } from "@arco-design/web-react";
import { getAssistantByWxIDApi, getWeChats } from "../../api/normalApi";

const Messages = () => {
  const [wxAccountList, setWxAccountList] = useState([]);
  const [currentWxAccount, setCurrentWxAccount] = useState(null);
  const [currentAssistant, setCurrentAssistant] = useState(null);
  // const [loadingAssistant, setLoadingAssistant] = useState(false); // 状态来控制加载
  const [threadId, setThreadId] = useState(null); //传给accountInfo获取background

  const fetchAccounts = async () => {
    try {
      // 后面刷新用
      // const response = await getWxAccountsApi().then(response => {
      // console.log(response)
      // });

      const weChatsResponse = await getWeChats();
      if (
        weChatsResponse.status === "success" ||
        weChatsResponse.status === 200
      ) {
        const data = localStorage.getItem("currentWxAccount");
        let storedData = null;
        if (data) {
          storedData = JSON.parse(data);
        }
        setWxAccountList(weChatsResponse.data);

        if (weChatsResponse.data.length > 0) {
          let wx_id = storedData
            ? storedData.wx_id
            : weChatsResponse.data[0].wx_id;
          await fetchAssistant(wx_id);
          // 存储的数据存在，并且在当前的微信列表中
          if (storedData && weChatsResponse.data.find((account) => account.wx_id === storedData.wx_id)) {
            setCurrentWxAccount(storedData);
          } else {
            setCurrentWxAccount(weChatsResponse.data[0]);
            try {
              localStorage.setItem(
                "currentWxAccount",
                JSON.stringify(weChatsResponse.data[0])
              );
            } catch (error) {
              console.error("localStorage Error:", error);
            }
          }
        }
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  const handleTabChange = (v) => {
    const foundAccount = wxAccountList.find((account) => account.wx_id === v);
    setCurrentWxAccount(foundAccount);
    // console.log(foundAccount)
    try {
      localStorage.setItem("currentWxAccount", JSON.stringify(foundAccount));
    } catch (error) {
      console.error("localStorage Error:", error);
    }
    if (foundAccount) {
      fetchAssistant(foundAccount.wx_id); // 根据选中的 wx_id 获取 Assistant
    } else {
      setCurrentAssistant(null);
    }
  };

  const fetchAssistant = async (wx_id) => {
    try {
      await getAssistantByWxIDApi(wx_id).then((response) => {
        if (response.status === "success" && response.data) {
          setCurrentAssistant(response.data);
        } else {
          setCurrentAssistant(null);
        }
      });
    } catch (error) {
      console.error("API Error:", error);
      setCurrentAssistant(null);
    }
  };

  // Fetch WeChat accounts on component mount
  useEffect(() => {
    fetchAccounts().then();
    // eslint-disable-next-line
  }, []);

  return (
    <Space align="start">
      <Space>
        {wxAccountList.length > 0 && (
          <Tabs
            scrollPosition="0"
            onChange={handleTabChange}
            type="text"
            activeTab={currentWxAccount ? currentWxAccount.wx_id : null} // Set the default active tab
            style={{ marginTop: 8, width: "87vw" }}
          >
            {wxAccountList.map((account) => (
              <Tabs.TabPane
                key={account.wx_id}
                title={
                  <Space>
                    <Avatar
                      shape="square"
                      size={30}
                      style={{ backgroundColor: "#3370ff" }}
                    >
                      {account.head_url ? (
                        <img
                          alt="头像"
                          // src={`data:image/png;base64,${account.head_img}`}
                          src={`${account.head_url}`}
                        />
                      ) : (
                        account.nickname.charAt(0)
                      )}
                    </Avatar>
                    {account.nickname}
                  </Space>
                }
              >
                {currentWxAccount &&
                  currentWxAccount.wx_id === account.wx_id && (
                    <Space align={"start"}>
                      <ChatList
                        // style={{ boxShadow: "3px 0 2px rgba(0, 0, 0, 0.03)" }}
                        currentWxAccount={currentWxAccount}
                        setThreadId={setThreadId}
                        assistant={currentAssistant}
                      />
                      {currentAssistant && (
                        <AssistantInfo
                          assistant={currentAssistant}
                          threadId={threadId}
                        />
                      )}
                    </Space>
                  )}
              </Tabs.TabPane>
            ))}
          </Tabs>
        )}
      </Space>
    </Space>
  );
};

export default Messages;
