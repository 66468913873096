import React, { useEffect, useState } from "react";
import {
  Input,
  List,
  Switch,
  Avatar,
  Typography,
  Modal,
  Space,
} from "@arco-design/web-react";
import { setAutoReplyForUser, getContactsApi } from "../../../api/normalApi";

const { Search } = Input;
const { Text } = Typography;

export const UserManagement = ({
  visible,
  setVisible,
  wxId,
  onAutoReplyChange,
}) => {
  const [contacts, setContacts] = useState([]);
  const [filteredContacts, setFilteredContacts] = useState([]);

  useEffect(() => {
    if (visible && wxId) {
      getContactsApi(wxId).then((res) => {
        if (res && res.contacts && res.contacts.length > 0) {
          const processedContacts = res.contacts.map((item) => ({
            ...item,
          }));
          setContacts(processedContacts);
          setFilteredContacts(processedContacts);
        }
      });
    }
  }, [visible, wxId]);

  const handleToggle = (item) => {
    const back_data = {
      wx_user_id: wxId,
      nickname: item.nickname,
      auto_reply: item.auto_reply ? "false" : "true",
    };

    setAutoReplyForUser(back_data).then((res) => {
      if (res.status === 200) {
        const updatedItem = { ...item, auto_reply: !item.auto_reply };
        setContacts((prev) =>
          prev.map((contact) =>
            contact.to_user_id === item.to_user_id ? updatedItem : contact,
          ),
        );
        setFilteredContacts((prev) =>
          prev.map((contact) =>
            contact.to_user_id === item.to_user_id ? updatedItem : contact,
          ),
        );
        onAutoReplyChange?.(updatedItem);
      }
    });
  };

  const handleSearch = (value) => {
    const filtered = contacts.filter((user) =>
      user.nickname.toLowerCase().includes(value.toLowerCase()),
    );
    setFilteredContacts(filtered);
  };

  return (
    <Modal
      title="管理自动回复"
      style={{ padding: "20px" }}
      visible={visible}
      onOk={() => setVisible(false)}
      onCancel={() => setVisible(false)}
    >
      <Search
        placeholder="搜索聊天"
        style={{ marginBottom: "16px", width: "100%" }}
        onChange={(v) => handleSearch(v)}
      />
      <List
        dataSource={filteredContacts}
        render={(item) => (
          <List.Item
            key={item.to_user_id}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Space
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Space style={{ display: "flex", alignItems: "center" }}>
                <Avatar size={40}>
                  <img alt="avatar" src={item.head_url} />
                </Avatar>
                <Text style={{ marginLeft: "12px" }}>{item.nickname}</Text>
              </Space>
              <Switch
                checked={item.auto_reply}
                onChange={() => handleToggle(item)}
                checkedText="开启"
                uncheckedText="关闭"
              />
            </Space>
          </List.Item>
        )}
      />
    </Modal>
  );
};

export default UserManagement;
