// ProductList.jsx
import React, {useState, useEffect, useRef} from 'react';
import {Table, Input, Button, Message, Space, Modal, Checkbox} from '@arco-design/web-react';
import {
    addProductApi,
    updateProductApi,
    getProductsApi,
    deleteProductApi,
    getAssistantProductsApi,
    delProductAssistantByIdApi,
    addProductAssistantApi
} from '../../../api/normalApi';
import PropTypes from 'prop-types';
import ProductCardModal from "./ProductCardModal";

const ProductListModal = ({columns, tableWidth, assistant_id, productListVisible, setProductListVisible}) => {
    const [productList, setProductList] = useState([]);
    const [editableRow, setEditableRow] = useState(null);
    const [addDisabled, setAddDisabled] = useState(false);
    const [modalVisible, setModalVisible] = useState(false); // 控制对话框可见性
    const editMode = useRef('')
    const [product, setProduct] = useState([]); //传递过去的商品
    const [newProduct, setNewProduct] = useState();
    const [selectedProducts, setSelectedProducts] = useState([]);// modal中选中的商品
    const [productListRelation, setProductListRelation] = useState([]) // 商品总关系表


    // 获取商品列表
    const getProducts = async () => {
        try {
            if(assistant_id){
                const res = await getAssistantProductsApi(assistant_id);
                if (res.status !== 'success') {
                    console.log('Failed to fetch assistant products')
                }
                setProductListRelation(res.data)
                const initSelectedList = res.data.map(item => item.product_id)
                setSelectedProducts(initSelectedList)
            }

            const response = await getProductsApi();
            if (response.status !== 'success') {
                console.log('Failed to fetch products')
            }
            let data = response.data;

            const productsWithKey = data.map((item, index) => ({
                ...item,
                key: index + 1,
                isNew: false,
            }));
            setProductList(productsWithKey);
        } catch (error) {
            Message.error('获取商品列表失败，请稍后重试');
            console.error(error);
        }
    };

    // 新增行
    const handleAdd = () => {
        setModalVisible(true)
        editMode.current = 'add'
        setProduct([])
    }

    const handleDelete = () => {
        if (product && product.id) {
            deleteProductApi(product.id).then(data => {
                if (data.status === 'success') {
                    Message.success("商品删除成功")
                    setModalVisible(false)
                    getProducts()
                } else {
                    Message.error("商品删除失败")
                    console.log(data)
                    setModalVisible(false)
                }
            })
        }
    }

    // 编辑行
    const handleEdit = (record) => {
        editMode.current = 'edit';
        setProduct({...record})
        setModalVisible(true)
    };

    // 保存行
    const handleSave = async () => {
        if (newProduct) {
            if (!newProduct.name || newProduct.price <= 0 || !newProduct.description || !newProduct.urls) {
                Message.error('请完善商品信息！');
                editMode.current = ''
                return; // Prevent further execution if validation fails
            }
        }

        if (editMode.current === 'add') {
            addProductApi({...newProduct}).then(response => {
                if (response.status === "success") {
                    // 如果有 assistant_id，则调用绑定商品和助手api
                    if (assistant_id) {
                        const data = [{
                            "product_id": response.data.id,
                            "assistant_id": assistant_id
                        }]
                        addProductAssistantApi(data).then(bindResponse => {
                            if (bindResponse.status !== 'success') {
                                Message.error('绑定商品和助手失败');
                            } else {
                                getProducts().then(() => {
                                    Message.success('新增商品成功');
                                });
                            }
                        });
                    } else {
                        getProducts().then(() => {
                            Message.success('新增商品成功');
                        });
                    }
                } else {
                    Message.error('新增商品失败');
                }
            });
        } else if (editMode.current === 'edit') {
            // 编辑商品
            const response = await updateProductApi(newProduct.id, {...newProduct});
            if (response.status === "success") {
                await getProducts();
                Message.success('修改商品成功');
            } else {
                Message.error('修改商品失败');
            }
        }
        setEditableRow(null);
        setAddDisabled(false);
    };

    // 处理字段变化
    const handleFieldChange = (value, key, field) => {
        const newData = productList.map((item) => {
            if (item.key === key) {
                return {...item, [field]: value};
            }
            return item;
        });
        setProductList(newData);
    };

    // 处理勾选
    const handleCheckboxChange = (id) => {
        setSelectedProducts((prevSelectedRows) => {
            if (prevSelectedRows.includes(id)) {
                return prevSelectedRows.filter(item => item !== id); // 取消选择
            } else {
                return [...prevSelectedRows, id]; // 添加选择
            }
        });
    };

    // 确认管理时增删商品
    const confirmProduct = async () => {
        const relationIds = productListRelation
            .filter(item => !selectedProducts.includes(item.product_id)) // 筛选 case_id 在 selectedCases 中的项
            .map(item => item.id);

        const unRelations = selectedProducts
            .filter(productId => !productListRelation.some(item => item.product_id === productId))
            .map(productId => ({
                product_id: productId,
                assistant_id: assistant_id //  筛选 selectedCases中新增的
            }));

        const promises = [];
        if (relationIds.length > 0) {
            promises.push(delProductAssistantByIdApi(JSON.stringify({"ids": relationIds})));
        }
        if (unRelations.length > 0) {
            promises.push(addProductAssistantApi(unRelations));
        }
        if (promises.length > 0) {
            await Promise.all(promises)
                .then(([response1, response2]) => {
                    Message.success("修改绑定关系成功！")
                    // if (response1) console.log('delCaseAssistantByIdApi response:', response1);
                    // if (response2) console.log('addCaseAssistantApi response:', response2);
                })
                .catch(error => {
                    console.log('修改绑定关系请求错误', error);
                });
            setProductListVisible(false)
        } else {
            setProductListVisible(false)
            // Message.info("没有修改绑定关系")
        }
    }

    // 定义所有可能的列
    const allColumns = [
        {
            title: '编号',
            dataIndex: 'key',
            width: 70,
        },
        {
            title: <Space>
                商品名称
            </Space>,
            width: 200,
            dataIndex: 'name',
            render: (text, record) => {
                return editableRow === record.key ? (
                    <Input
                        value={record.name}
                        onChange={(value) => handleFieldChange(value, record.key, 'name')}
                    />
                ) : (
                    text
                );
            },
        },
        {
            title: '价格',
            dataIndex: 'price',
            render: (text, record) => {
                return editableRow === record.key ? (
                    <Input
                        value={record.price}
                        onChange={(value) => handleFieldChange(value, record.key, 'price')}
                    />
                ) : (
                    text
                );
            },
        },
        {
            title: '商品介绍',
            dataIndex: 'description',
            render: (text, record) => {
                return editableRow === record.key ? (
                    <Input
                        value={record.description}
                        onChange={(value) => handleFieldChange(value, record.key, 'description')}
                    />
                ) : (
                    text
                );
            },
        },
        {
            title: '访问链接',
            dataIndex: 'urls',
            render: (text, record) => {
                return editableRow === record.key ? (
                    <Input
                        value={record.urls}
                        onChange={(value) => handleFieldChange(value, record.key, 'urls')}
                    />
                ) : (
                    <a href={text} target="_blank" rel="noopener noreferrer">
                        {text || '暂无链接'}
                    </a>
                );
            },
        },
        {
            title: <Button
                size={'small'}
                type="primary"
                onClick={handleAdd}
                disabled={addDisabled}
            >新建</Button>,
            dataIndex: 'actions',
            width: 80,
            render: (_, record) => {
                return (
                    <Button onClick={() => {
                        handleEdit(record)
                    }} size={'small'} type={"text"}>编辑</Button>
                )
            },
        },
        {
            title: '选择',
            dataIndex: 'check',
            render: (_, record) => {
                return (
                    <Checkbox
                        checked={selectedProducts.includes(record.id)}
                        onChange={() => handleCheckboxChange(record.id)}
                    />
                );
            },
        },
    ];

    // 根据传入的 columns prop 过滤要显示的列，如果未传入，则显示所有列
    const displayedColumns = columns
        ? allColumns
            .filter(col => columns.includes(col.dataIndex))
        : allColumns;

    useEffect(() => {
        getProducts();
        // eslint-disable-next-line
    }, [assistant_id]);

    useEffect(() => {
        if (newProduct) {
            handleSave()
        }
        // eslint-disable-next-line
    }, [newProduct]);

    return (
        <Modal
            title='勾选商品绑定到数字员工（允许员工在聊天中推荐已选中的商品）'
            visible={productListVisible}
            onOk={confirmProduct}
            onCancel={() => setProductListVisible(false)}
            style={{width: '60vw'}}
        >
            <Space direction='vertical' style={{width: "100%"}}>
                <Table
                    size={'small'}
                    columns={displayedColumns}
                    data={productList}
                    pagination={false}
                    style={{minWidth: tableWidth}}
                />
                <ProductCardModal
                    visible={modalVisible}
                    onClose={() => setModalVisible(false)}
                    product={product}
                    setProduct={setNewProduct}
                    handleDelete={handleDelete}
                    assistant_id={assistant_id}
                />
            </Space>
        </Modal>
    );
};

// 定义 propTypes
ProductListModal.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.string),
    tableWidth: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    assistant_id: PropTypes.string, // Add assistant_id to propTypes
};

export default ProductListModal;