import React, {useState, useEffect, useRef} from "react";
import {Button, Message, Space, Table, Tag} from "@arco-design/web-react";
import {
    addCaseApi,
    addCaseAssistantApi,
    deleteCaseApi,
    getAssistantCasesApi, getAssistantsIdApi,
    getCasesApi,
    updateCaseApi,
} from "../../../api/normalApi";
import PropTypes from "prop-types";
import AddCaseCard from "./AddCaseCard";
import CaseMaterialModal from "./CaseMaterialModal";
import {addFileToVectorStore, listVectorStoreFiles} from "../../../api/openaiAPI";

const CaseMaterial = ({
                          columns,
                          tableWidth,
                          selectedEmployee = null, employee,
                          setCases,
                          mode,
                      }) => {
    const [caseList, setCaseList] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const editMode = useRef("");
    const [currentCase, setCurrentCase] = useState({});
    const [caseListVisible, setCaseListVisible] = useState(false); //勾选对话是否可见

    useEffect(() => {
        if (!caseListVisible) {
            setTimeout(() => getCases(), 5000);
        }
        // eslint-disable-next-line
    }, [caseListVisible]);

    // 获取案例列表
    const getCases = async () => {
        try {
            let data;
            if (selectedEmployee !== null) {
                const response = await getAssistantCasesApi(selectedEmployee.id);
                if (response.status !== "success") {
                    console.log("Failed to fetch assistant cases");
                }
                data = response.data.map((item) => item.case);
                const filteredCases = data.map((item) => ({
                    case_name: item.case_name,
                    description: item.description,
                    file_url: item.file_url,
                    openai_file_id: item.openai_file_id,
                    user_id: item.user_id,
                    is_internal: item.is_internal,
                }));
                // console.log("filteredCases", filteredCases);
                setCases(filteredCases);
            } else {
                const response = await getCasesApi();
                if (response.status !== "success") {
                    console.log("Failed to fetch products");
                }
                data = response.data;
            }
            const casesWithKey = data.map((item, index) => ({
                ...item,
                key: index + 1,
            }));
            setCaseList(casesWithKey);
        } catch (error) {
            Message.error("获取案例列表失败，请稍后重试");
        }
    };

    const extractFileName = (filePath) => {
        if (!filePath) return ""; // 如果路径为空，返回空字符串

        // 使用正则表达式提取最后一个斜杠后面的部分
        return filePath.split("/").pop();
    };

    useEffect(() => {
        getCases();
        // eslint-disable-next-line
    }, []);

    // 新增案例行
    const handleAdd = () => {
        setModalVisible(true);
        editMode.current = "add";
        setCurrentCase("");
    };

    // 编辑案例
    const handleEdit = (record) => {
        setModalVisible(true);
        editMode.current = "edit";
        setCurrentCase(record);
    };

    // 删除案例
    const handleDelete = async () => {
        if (currentCase && currentCase.id) {
            const response = await deleteCaseApi(currentCase.id);
            if (response.status === "success") {
                Message.success("删除案例成功");
                setModalVisible(false);
                await getCases();
            } else {
                Message.error("删除案例失败");
                setModalVisible(false);
            }
        }
    };

    // 保存案例
    const handleSave = async (updatedCase) => {
        console.log("save-case-page-table",editMode)
        try {
            if (editMode.current === "add") {
                try {
                    const addResponse = await addCaseApi({...updatedCase});

                    if (addResponse.status === "success") {
                        if (selectedEmployee) {
                            const assistantResponse = await getAssistantsIdApi(selectedEmployee.id);
                            // let vectorStoreResponse = await listVectorStoreFiles(assistantResponse.data.openai_vs_id);
                            // let nowVsFileId = vectorStoreResponse.data;

                            const data = [{
                                case_id: addResponse.data.id,
                                assistant_id: selectedEmployee.id,
                            }];

                            try {
                                const bindResponse = await addCaseAssistantApi(data);
                                await addFileToVectorStore(assistantResponse.data.openai_vs_id, updatedCase.openai_file_id);

                                if (bindResponse.status !== "success") {
                                    return Message.error("绑定案例和助手失败");
                                }

                                await getCases();
                                Message.success("新增案例成功");
                            } catch (bindError) {
                                console.error("绑定案例和助手出错:", bindError);
                                Message.error("绑定过程中发生错误");
                            }
                        } else {
                            await getCases();
                            Message.success("新增案例成功");
                        }
                    } else {
                        Message.error("新增案例失败");
                    }
                } catch (addError) {
                    console.error("新增案例出错:", addError);
                    Message.error("新增案例过程中发生错误");
                }
            } else if (editMode.current === "edit") {
                try {
                    if (selectedEmployee) {
                        const assistantResponse = await getAssistantsIdApi(selectedEmployee.id);
                        let vectorStoreResponse = await listVectorStoreFiles(assistantResponse.data.openai_vs_id);
                        let nowVsFileId = vectorStoreResponse.data;
                        const isExist = nowVsFileId.some(item => item.id === updatedCase.openai_file_id);

                        if (!isExist) {
                            await addFileToVectorStore(assistantResponse.data.openai_vs_id, updatedCase.openai_file_id);
                        }
                    }

                    const updateResponse = await updateCaseApi(updatedCase.id, updatedCase);

                    if (updateResponse.status === "success") {
                        Message.success("修改案例成功");
                        await getCases();
                    } else {
                        Message.error("修改案例失败");
                    }
                } catch (updateError) {
                    console.error("更新案例出错:", updateError);
                    Message.error("更新案例过程中发生错误");
                }
            }

            setModalVisible(false);
        } catch (error) {
            console.error("处理保存操作出错:", error);
            Message.error("保存过程中发生错误");
        }
    };

    // 定义所有可能的列
    const allColumns = [
        {
            title: "编号",
            dataIndex: "key",
            width: 70,
        },
        {
            title: (
                <Space>
                    资料名称
                    {mode === "displayModal" && (
                        <Button type="text" onClick={() => setCaseListVisible(true)}>
                            管理
                        </Button>
                    )}
                </Space>
            ),
            dataIndex: "case_name",
            width: 200,
        },
        {
            title: "文件描述",
            dataIndex: "description",
        },
        {
            title: "案例文件",
            dataIndex: "file_url",
            render: (text) => (
                <Tag href={text} target="_blank" rel="noopener noreferrer" color={"blue"}>
                    {text ? extractFileName(text) : "暂无文件"}
                </Tag>
            ),
        },
        {
            title: "类型",
            dataIndex: "is_internal",
            render: (text) => (
                <Tag color={text === true ? "orange" : "green"}>
                    {text === true ? "内部" : "公开"}
                </Tag>
            ),
        },
        {
            title: (
                <div style={{display: "flex", justifyContent: "flex-end"}}>
                    <Button size={"small"} type="primary" onClick={handleAdd}>
                        新建
                    </Button>
                </div>
            ),
            dataIndex: "actions",
            align: "right", // 将这一列内容向右对齐
            render: (_, record) => (
                <div style={{textAlign: "right"}}>
                    <Button size={"small"} onClick={() => handleEdit(record)} type={"text"}>
                        编辑
                    </Button>
                </div>
            ),
        },
    ];

    const displayedColumns = columns
        ? allColumns.filter((col) => columns.includes(col.dataIndex))
        : allColumns;

    return (
        <Space direction="vertical" style={{width: "100%"}}>
            <Table
                size={"small"}
                columns={displayedColumns}
                data={caseList}
                pagination={false}
                rowKey="key"
                style={{minWidth: tableWidth}}
            />
            <CaseMaterialModal
                caseListVisible={caseListVisible}
                setCaseListVisible={setCaseListVisible}
                selectedEmployee={selectedEmployee}
                employee={employee}
                handleSave={handleSave}
            />
            <AddCaseCard
                visible={modalVisible}
                onClose={() => setModalVisible(false)}
                caseData={currentCase}
                setCaseData={setCurrentCase}
                handleSave={handleSave}
                handleDelete={handleDelete}
            />
        </Space>
    );
};

CaseMaterial.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.string),
    tableWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    assistant_id: PropTypes.string,
};
// // 设置默认Props
// CaseCardModal.defaultProps = {
//     assistant_id: null,
// };
export default CaseMaterial;
